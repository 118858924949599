import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@involve-software/uikit";

import ErrorStatusLabel from "modules/result/components/StatusLabels/Error";
import { LinkButton } from "../index.styled";

const CardTransferFailedStatus = () => {
  const [searchParams] = useSearchParams();

  const returnUrl = searchParams.get("returnUrl");

  const { t } = useTranslation("result");

  return (
    <>
      <ErrorStatusLabel label={t("error.label")} />
      {returnUrl && (
        <LinkButton href={returnUrl}>
          <Button variant="outlined">{t("common:returnButton")}</Button>
        </LinkButton>
      )}
    </>
  );
};

export default CardTransferFailedStatus;
