import React from "react";
import { Route } from "react-router-dom";

import type { Language } from "data/i18n";
import { changeLanguage } from "utils/language";
import ErrorPage from "./pages";

export default (
  <Route
    path="error"
    element={<ErrorPage />}
    loader={async ({ request }) => {
      const lng = new URL(request.url).searchParams.get("lng");
      if (lng) {
        await changeLanguage(lng as Language);
      }
      return null;
    }}
  />
);
