import React from "react";
import { useTheme } from "@involve-software/uikit";

import * as Styled from "./index.styled";

const LoadingOverlay = () => {
  const { icons } = useTheme();

  return (
    <Styled.LoadingOverlay fullScreen>
      <icons.logo.component />
    </Styled.LoadingOverlay>
  );
};

export default LoadingOverlay;
