import React from "react";
import { useTranslation } from "react-i18next";

import type { PaymentInfo } from "api/payment/info";
import type { LocaleText } from "types/locales";

import * as Styled from "../index.styled";

type WarningBlockProps = {
  isLoading: boolean;
  data?: PaymentInfo;
};

const WarningBlock: React.FC<WarningBlockProps> = ({ isLoading, data }) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      {isLoading && <Styled.TopInfoLoadingSkeleton />}
      {data && (
        <Styled.TopAlert title={t("common:alerts.important")} variant="warning">
          <div
            dangerouslySetInnerHTML={{
              __html: data.text[i18n.language as keyof LocaleText],
            }}
          />
        </Styled.TopAlert>
      )}
    </>
  );
};

export default WarningBlock;
