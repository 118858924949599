import React from "react";
import { Link as UILink, useTheme } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { createCustomLinkComponent } from "components/Link";

import * as Styled from "./index.styled";

const Link = createCustomLinkComponent(Styled.MethodsLink as any);

const MethodsLink: React.FC<React.ComponentProps<typeof UILink>> = (props) => {
  const { paymentId } = useParams();

  const { t } = useTranslation("payment");

  const { icons } = useTheme();

  return (
    <Link to={`/payment/${paymentId}/methods`} {...props}>
      <Styled.NavIcon>
        <icons.actions.arrowLeft.component />
      </Styled.NavIcon>
      {t("details.backLink")}
    </Link>
  );
};

export default MethodsLink;
