import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import WrongParamsError from "components/Errors/WrongParams";

import CactusBanksSelection from "./pages/CactusBanksSelection";
import CactusBanksPayment from "./pages/CactusBanksPayment";

import { cactusBanksLoader, cactusBanksRedirectLoader } from "./loaders";

import CactusBanksPaymentLayout from "./layout";

export default (
  <Route
    id="cactusBanks"
    path="cactus_v2/:paymentId"
    loader={cactusBanksLoader}
    element={<CactusBanksPaymentLayout />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  >
    <Route path="" loader={cactusBanksRedirectLoader} />
    <Route path="banks" element={<CactusBanksSelection />} />
    <Route path="payment" element={<CactusBanksPayment />} />
  </Route>
);
