import React from "react";
import { useLoaderData } from "react-router-dom";

import InvoiceCard from "./components/InvoiceCard";
import QPaymentsCard from "./components/QPaymentsCard";
import { IQPaymentsSession } from "api/payment/qPayments";
import * as Styled from "./index.styled";

const QPayments = () => {
  const { amount, currency, description, paymentId, phone } =
    useLoaderData() as IQPaymentsSession;

  return (
    <Styled.QPayments>
      <InvoiceCard
        id={paymentId}
        sum={amount}
        currency={currency.alias}
        description={description}
      />
      <QPaymentsCard phone={phone} />
    </Styled.QPayments>
  );
};

export default QPayments;
