import { css } from "styled-components/macro";

export const boxCSS = css`
  padding: ${({ theme }) => theme.spacing.primitives["s-16"]}
    ${({ theme }) => theme.spacing.primitives["s-12"]};
  background-color: ${({ theme }) => theme.colors["layer-03"]};
  border-radius: ${({ theme }) => theme.radius["radius-cards-strong"]};
`;

export const activeTriggerCSS = css`
  & > svg {
    transform: rotate(180deg);
  }
`;
