import QRCodeStyling from "qr-code-styling";

export const QR_SIZE = 188;

export const qrCode = new QRCodeStyling({
  width: QR_SIZE,
  height: QR_SIZE,
  backgroundOptions: {
    color: "rgb(251, 252, 253)",
  },
  imageOptions: {
    imageSize: 0.5,
    margin: 4,
  },
});
