import React, { FC } from "react";
import { SkeletonStyleProps } from "react-loading-skeleton";

import * as Styled from "./index.styled";

const InputsLoadingSkeleton: FC<SkeletonStyleProps> = (props) => (
  <>
    <Styled.Item>
      <Styled.StartItemLabel>
        <Styled.LoadingSkeleton {...props} />
      </Styled.StartItemLabel>
      <Styled.InputContent>
        <Styled.LoadingSkeleton {...props} />
      </Styled.InputContent>
    </Styled.Item>
    <Styled.Item>
      <Styled.StartItemLabel>
        <Styled.LoadingSkeleton {...props} />
      </Styled.StartItemLabel>
      <Styled.InputContent>
        <Styled.LoadingSkeleton {...props} />
      </Styled.InputContent>
    </Styled.Item>
  </>
);

export default InputsLoadingSkeleton;
