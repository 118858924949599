import { Card } from "@involve-software/uikit";
import styled from "styled-components";

import { down } from "utils/breakpoints";

export const QrCard = styled(Card)`
  padding-top: ${({ theme }) => theme.spacing.primitives["s-24"]};
  padding-bottom: ${({ theme }) => theme.spacing.primitives["s-36"]};
  width: 100%;

  ${down("md")} {
    padding-bottom: ${({ theme }) => theme.spacing.primitives["s-48"]};
  }

  ${down("sm")} {
    padding-left: ${({ theme }) => theme.spacing.primitives["s-12"]};
    padding-right: ${({ theme }) => theme.spacing.primitives["s-12"]};
  }
`;

export const QrHeading = styled(Card.Heading)`
  margin: 0;
  padding-bottom: ${({ theme }) => theme.spacing.primitives["s-16"]};
  border-bottom: 1px solid ${({ theme }) => theme.colors["border-subtle-01"]};

  ${down("md")} {
    border: none;
  }
`;

export const QrTitle = styled(Card.Title)`
  font-size: 18px;

  ${down("md")} {
    font-size: 16px;
  }
`;

export const QrHeadingActions = styled(Card.HeadingActions)`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
`;

export const Amount = styled.h3`
  font: ${({ theme }) => theme.typography["heading-large"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
  margin: 0;
`;

export const AmountLabel = styled.span`
  font: ${({ theme }) => theme.typography["label-medium"]};
  color: ${({ theme }) => theme.colors["text-subtle"]};
`;

export const QrMethodLogo = styled.div`
  margin: ${({ theme }) => theme.spacing.primitives["s-24"]} auto
    ${({ theme }) => theme.spacing.primitives["s-28"]};
  width: fit-content;
`;

export const QrCode = styled.div`
  max-width: 300px;
  margin: 0 auto ${({ theme }) => theme.spacing.primitives["s-16"]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};

  p {
    margin: 0;
    text-align: center;
    font: ${({ theme }) => theme.typography["label-small"]};
    color: ${({ theme }) => theme.colors["text-primary"]};
  }

  img {
    max-width: 200px;
    max-height: 200px;
    width: 100%;
    height: 100%;
  }
`;

export const BinanceMobileAppLink = styled.a`
  display: block;
  width: fit-content;
  margin: 0 auto ${({ theme }) => theme.spacing.primitives["s-12"]};
  text-decoration: none;

  ${down("xs")} {
    width: 100%;
    button {
      width: 100%;
      font: ${({ theme }) => theme.typography["label-small"]};
      padding-left: ${({ theme }) => theme.spacing.primitives["s-04"]};
      padding-right: ${({ theme }) => theme.spacing.primitives["s-04"]};
      gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
    }
  }
`;

export const BinanceWebApp = styled.p`
  font: ${({ theme }) => theme.typography["paragraph-small"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
  margin-bottom: ${({ theme }) => theme.spacing.primitives["s-16"]};
  text-align: center;

  a {
    font: ${({ theme }) => theme.typography["label-small"]};
    color: ${({ theme }) => theme.colors["text-brand"]};
    text-decoration: none;
  }
`;

export const BinancePayButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-08"]};
`;

export const TextButtonLink = styled.a`
  text-decoration: underline;
  text-decoration-thickness: 0.5px;
  color: inherit;
`;

export const ContainedButtonLink = styled.a`
  text-decoration: none;
`;
