import styled from "styled-components";
import { Button, Link } from "@involve-software/uikit";

export const ActionButton = styled(Button)`
  max-width: 240px;
  margin-top: ${({ theme }) => theme.spacing.primitives["s-28"]};
`;

export const LinkButton = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
`;

export const StatusDescription = styled.div`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-16"]};
  text-align: center;
  max-width: 325px;
  font: ${({ theme }) => theme.typography["paragraph-medium"]};
  color: ${({ theme }) => theme.colors["text-secondary"]};
`;
