export const AVAILABLE_LNGS =
  process.env.REACT_APP_THEME === "ewallet"
    ? ["en"]
    : (["ru", "en", "uk", "pl", "az", "kk"] as const);
export const FALLBACK_LNG = "en";

export type Language = (typeof AVAILABLE_LNGS)[number];

export const LANGUAGES: Record<
  Language,
  {
    name: string;
    code: Language;
  }
> = {
  ru: {
    name: "Русский",
    code: "ru",
  },
  en: {
    name: "English",
    code: "en",
  },
  uk: {
    name: "Українська",
    code: "uk",
  },
  pl: {
    name: "Polski",
    code: "pl",
  },
  az: {
    name: "Azərbaycan",
    code: "az",
  },
  kk: {
    name: "Kazakh",
    code: "kk",
  },
};
