export enum RequestMethod {
  get = "get",
  post = "post",
  put = "put",
  delete = "delete",
  head = "head",
  patch = "patch",
}

export enum PaymentSessionType {
  FULL,
  PRE,
  AUTO,
}
