import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Link, List } from "@involve-software/uikit";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import type { FormValues } from "modules/crypto/data/types";
import BlockchainSelectDialog from "../BlockchainSelectDialog";
import { useMediaQuery, getMedia } from "utils/breakpoints";
import LoadingOverlay from "components/LoadingOverlay";
import { EMAIL_REGEX } from "data/validation";
import { getPmImgSrc } from "utils/media";
import { env } from "utils/env";

import * as Styled from "./index.styled";

interface PaymentDetailsProps {
  paymethodId: number;
  sessionPaymethodId?: string;
  paymethodName: string;
  initialValues?: Partial<FormValues>;
}

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  paymethodId,
  sessionPaymethodId,
  paymethodName,
  initialValues = {},
}) => {
  const { paymentId } = useParams();
  const navigate = useNavigate();
  const navigation = useNavigation();

  const [loading, setLoading] = useState(false);

  const isDesktop = useMediaQuery(getMedia("md", "min"));

  const { t, i18n } = useTranslation("crypto");

  const [isConditionsAccepted, setIsConditionsAccepted] = useState(true);

  const {
    formState: { errors, isSubmitting, isSubmitSuccessful },
    register,
    watch,
    setValue,
    handleSubmit: handleRhkSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      blockchain: null,
      email: "",
      ...initialValues,
    },
  });

  useEffect(() => {
    if (
      isSubmitting ||
      (isSubmitSuccessful && navigation.state === "loading")
    ) {
      setLoading(true);
      return () => setLoading(false);
    }
  }, [navigation.state, isSubmitting, isSubmitSuccessful]);

  const blockchainValue = watch("blockchain");
  const { onChange: onBlockchainChange, ...blockchainHandlers } = register(
    "blockchain",
    { required: t("details.fields.blockchain.errors.required") }
  );

  const handleSubmit = (data: FormValues) => {
    const payway = (data.blockchain as Exclude<FormValues["blockchain"], null>)
      .alias;

    navigate({
      pathname: `/crypto/payment/${paymentId}/payment`,
      search: `?email=${data.email}&payway=${payway}`,
    });
  };

  return (
    <Styled.PaymentDetails>
      {loading && <LoadingOverlay />}
      <Card.Heading>
        <Card.HeadingTitle>
          <Card.Title>{t("details.title")}</Card.Title>
        </Card.HeadingTitle>
      </Card.Heading>
      <Styled.DetailsContent $dividerDisabled>
        <Styled.PaymentDetailsForm onSubmit={handleRhkSubmit(handleSubmit)}>
          <Styled.DetailsList>
            {(!initialValues.blockchain ||
              (initialValues.blockchain && sessionPaymethodId)) && (
              <Styled.PayMethodItem>
                {!isDesktop && (
                  <Styled.PayMethodLabel>
                    {t("details.payMethod.title")}
                  </Styled.PayMethodLabel>
                )}
                <Styled.PayMethodContent
                  $decorationLabel={
                    isDesktop ? t("details.payMethod.title") : undefined
                  }
                >
                  <Styled.PayMethodIcon>
                    <img
                      src={getPmImgSrc(+paymethodId, true)}
                      alt={t("details.payMethod.title")}
                    />
                  </Styled.PayMethodIcon>
                  {paymethodName}
                </Styled.PayMethodContent>
              </Styled.PayMethodItem>
            )}
            {(!initialValues.blockchain ||
              (initialValues.blockchain && sessionPaymethodId)) && (
              <List.Item>
                <Styled.InputContent
                  $decorationLabel={
                    isDesktop && t("details.fields.blockchain.label")
                  }
                >
                  <Styled.BlockchainSelect
                    value={blockchainValue?.id}
                    renderValue={() =>
                      blockchainValue ? (
                        <Styled.BlockchainValue>
                          {blockchainValue.name}
                        </Styled.BlockchainValue>
                      ) : null
                    }
                    Menu={BlockchainSelectDialog}
                    renderInPortal={false}
                    renderInViewport={false}
                    placeholder={t("details.fields.blockchain.placeholder")}
                    onValueChange={(value: FormValues["blockchain"]) => {
                      if (!value) {
                        return;
                      }

                      setValue("blockchain", value, {
                        shouldValidate: true,
                      });
                    }}
                    // TODO: Fix Select errorText margin in UIKit
                    errorText={errors.blockchain?.message}
                    fullWidth
                    label={!isDesktop && t("details.fields.blockchain.label")}
                    InputProps={{
                      tabIndex: -1,
                    }}
                    {...blockchainHandlers}
                  />
                </Styled.InputContent>
              </List.Item>
            )}
            {!initialValues.email && (
              <List.Item>
                <Styled.InputContent $decorationLabel={isDesktop && "Email"}>
                  <Styled.EmailInput
                    placeholder={t("details.fields.email.placeholder")}
                    fullWidth
                    errorText={errors.email?.message}
                    InputProps={register("email", {
                      required: t("details.fields.email.errors.required"),
                      pattern: {
                        value: EMAIL_REGEX,
                        message: t("details.fields.email.errors.format"),
                      },
                    })}
                    label={!isDesktop && "Email"}
                  />
                </Styled.InputContent>
              </List.Item>
            )}
          </Styled.DetailsList>
          <Styled.Confirmation>
            <Checkbox
              onChange={() => setIsConditionsAccepted(!isConditionsAccepted)}
              checked={isConditionsAccepted}
              label={
                <Trans i18nKey="common:tNsConditions">
                  I agree to
                  <Link
                    href={
                      env.REACT_APP_AGREEMENT_LINK +
                      `?lang=${i18n.language}`
                    }
                  >
                    {" "}
                    T&C terms
                  </Link>
                </Trans>
              }
            />

            <Button disabled={!isConditionsAccepted} type="submit" fullWidth>
              {t("details.actions.pay")}
            </Button>
          </Styled.Confirmation>
        </Styled.PaymentDetailsForm>
      </Styled.DetailsContent>
    </Styled.PaymentDetails>
  );
};

export default PaymentDetails;
