import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import WrongParamsError from "components/Errors/WrongParams";
import NcpayPayment from "./pages/NcpayPayment";

import { ncpayLoader } from "./loaders";

export default (
  <Route
    path="ncpay/:paymentId"
    loader={ncpayLoader}
    element={<NcpayPayment />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  />
);
