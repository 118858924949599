import React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@involve-software/uikit";

import type { DetailsData } from "modules/crypto/data/loader-data";
import SideInfoSection from "modules/crypto/components/SideInfoSection";
import DetailsSection from "modules/crypto/components/DetailsSection";
import { getMedia, useMediaQuery } from "utils/breakpoints";

import * as Styled from "./index.styled";

const CryptoPayment: React.FC = () => {
  const navigate = useNavigate();
  const detailsData = useLoaderData() as DetailsData;

  const isDesktop = useMediaQuery(getMedia("md", "min"));

  const { t } = useTranslation("crypto");
  const { icons } = useTheme();

  return (
    <Styled.CryptoPayment>
      <Styled.PaymentContent $dividerDisabled>
        {!detailsData.isPrefilled && (
          <Styled.PrevStepLink onClick={() => navigate(-1)}>
            <Styled.PrevStepIcon>
              <icons.actions.arrowLeft.component />
            </Styled.PrevStepIcon>
            {t("navigation.prev")}
          </Styled.PrevStepLink>
        )}
        <Styled.Sections $isMargin={!detailsData.isPrefilled}>
          <DetailsSection detailsData={detailsData} />
          {isDesktop && <SideInfoSection infoText={detailsData.infoText} />}
        </Styled.Sections>
      </Styled.PaymentContent>
    </Styled.CryptoPayment>
  );
};

export default CryptoPayment;
