import styled from "styled-components";

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
`;

export const AddressTitle = styled.span`
  font: ${({ theme }) => theme.typography["heading-x-small"]};
  color: ${({ theme }) => theme.colors["text-subtle"]};
`;

export const AddressContent = styled.span`
  font: ${({ theme }) => theme.typography["label-medium"]};
  display: flex;
  gap: ${({ theme }) => theme.spacing.primitives["s-16"]};
`;

export const AddressValue = styled.span`
  width: 100%;
  word-break: break-all;
  color: ${({ theme }) => theme.colors["text-primary"]};
  font: ${({ theme }) => theme.typography["label-medium"]};
`;

export const AddressActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-08"]};
  height: fit-content;
`;
