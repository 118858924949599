import type { LocaleText } from "types/locales";
import { factory, getAuthorization } from "api/base";
import { RequestMethod } from "utils/constants";

export interface PaymentInfo {
  name: string;
  text: LocaleText;
}

export type PaymentInfoType = "info" | "warning" | "manual";

export const getPaymentInfo = factory(
  (r) => (paymentId: string, type: PaymentInfoType, infoId?: number) =>
    r<PaymentInfo>(RequestMethod.get, `/${type}/${infoId}`, undefined, {
      headers: getAuthorization(paymentId),
    })
);
