import { type LoaderFunction, redirect } from "react-router-dom";

import { PaymentStatus } from "api/payment/status";
import { PaymentsType } from "types/payment";

import {
  BankCactusPaymentStatus,
  getBanksCactusPaySession,
  updateBanksCactusPaySession,
} from "api/payment/banks";

export const cactusBanksLoader: LoaderFunction = async ({
  params,
  request,
}) => {
  if (!params.paymentId) {
    throw new Error("Required params are missing");
  }

  const url = new URL(request.url);

  const { data } = await getBanksCactusPaySession(params.paymentId);

  const isPaymentFinished =
    data.status === BankCactusPaymentStatus.SUCCESS ||
    data.status === BankCactusPaymentStatus.FAILED;

  if (isPaymentFinished) {
    return redirect(
      `/result-page/${params.paymentId}?status=${data.status}&type=${PaymentsType.CACTUS}`
    );
  }

  if (!data.bankSent && url.pathname.endsWith("/payment")) {
    const bank = url.searchParams.get("bank");

    if (!bank) {
      throw new Error("Required params are missing");
    }

    try {
      await updateBanksCactusPaySession(params.paymentId!, bank);

      const { data: updatedData } = await getBanksCactusPaySession(
        params.paymentId
      );

      if (
        updatedData.bankSent &&
        (!updatedData.card || !updatedData.cardHolder)
      ) {
        return redirect(
          `/result-page/${params.paymentId}?status=${PaymentStatus.REJECTED}&type=${PaymentsType.CACTUS}&isCredentialsFetchError=true`
        );
      }

      return updatedData;
    } catch (e) {
      throw new Error(e as string);
    }
  }

  return data;
};

export const cactusBanksRedirectLoader: LoaderFunction = async ({ params }) => {
  return redirect(`/cactus_v2/${params.paymentId}/banks`);
};
