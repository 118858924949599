import styled from "styled-components";
import { Link } from "react-router-dom";

import { down } from "utils/breakpoints";

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 920px;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.primitives["s-28"]};
  gap: ${({ theme }) => theme.spacing.primitives["s-12"]};

  ${down("sm")} {
    flex-direction: column;
  }
`;

export const SecureIcons = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-28"]};

  &:only-child {
    margin: 0 auto;
  }

  ${down("sm")} {
    gap: ${({ theme }) => theme.spacing.primitives["s-20"]};

    & > svg {
      width: 62px;
    }
  }
`;

export const SupportLink = styled(Link)`
  text-decoration: none;

  ${down("sm")} {
    button {
      font: ${({ theme }) => theme.typography["label-small"]};
    }
  }
`;
