import { Card, Link } from "@involve-software/uikit";
import styled from "styled-components";

import { down } from "utils/breakpoints";

export const CryptoPayment = styled(Card)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.primitives["s-28"]};

  ${down("md")} {
    padding: ${({ theme }) => theme.spacing.primitives["s-20"]};
    ${({ theme }) => theme.spacing.primitives["s-16"]} ${({ theme }) =>
      theme.spacing.primitives["s-20"]};
  }
`;

export const PrevStepLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
  color: ${({ theme }) => theme.colors["text-brand"]};
`;

export const PrevStepIcon = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaymentContent = styled(Card.Content)`
  padding: 0;
`;

export const Sections = styled.div<{ $isMargin: boolean }>`
  margin-top: ${({ theme, $isMargin }) =>
    $isMargin && theme.spacing.primitives["s-24"]};
  display: flex;
  gap: ${({ theme }) => theme.spacing.primitives["s-24"]};
`;
