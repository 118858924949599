import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import WrongParamsError from "components/Errors/WrongParams";
import { paymentStatusLoader } from "./loaders";
import PaymentResult from "./pages/result";

export default (
  <Route
    path="result-page/:paymentId"
    loader={paymentStatusLoader}
    element={<PaymentResult />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  />
);
