import styled from "styled-components/macro";
import { Card, List } from "@involve-software/uikit";

import { down } from "utils/breakpoints";

export const InvoiceCard = styled(Card)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.primitives["s-24"]};

  ${down("md")} {
    padding: ${({ theme }) => theme.spacing.primitives["s-24"]};
  }
`;

export const Title = styled(Card.Title)`
  font-size: 18px;
`;

export const Heading = styled(Card.Heading)`
  margin-bottom: ${({ theme }) => theme.spacing.primitives["s-16"]};
`;

export const Content = styled(Card.Content)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-24"]};
`;

export const DetailsList = styled(List)`
  ul {
    gap: ${({ theme }) => theme.spacing.primitives["s-16"]};
  }

  ${down("md")} {
    width: 100%;
  }
`;

export const DetailsItem = styled(List.Item)`
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.primitives["s-24"]};
  font: ${({ theme }) => theme.typography["label-medium"]};
  white-space: nowrap;

  ${down("md")} {
    justify-content: space-between;
    font: ${({ theme }) => theme.typography["label-small"]};
  }
`;

export const DetailsLabel = styled(List.Label)`
  min-width: 130px;
`;

export const DetailsContent = styled(List.Content)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const DetailsSumLabel = styled(DetailsLabel)`
  color: ${({ theme }) => theme.colors["text-primary"]};
`;

export const PaymentSumInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
`;

export const Sum = styled.div`
  font: ${({ theme }) => theme.typography["heading-medium"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
`;

export const SumSubtitle = styled.div`
  font: ${({ theme }) => theme.typography["label-small"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
`;
