import styled from "styled-components/macro";
import { down } from "utils/breakpoints";

export const PaymentExpiryTimer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  flex-shrink: 0;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
  padding: ${({ theme }) => theme.spacing.primitives["s-04"]}
    ${({ theme }) => theme.spacing.primitives["s-08"]}
    ${({ theme }) => theme.spacing.primitives["s-04"]};
  background-color: ${({ theme }) => theme.colors["layer-02"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
  border-radius: ${({ theme }) => theme.radius["radius-pr-subtle"]};
  font: ${({ theme }) => theme.typography["label-medium"]};

  & > svg {
    flex-shrink: 0;
  }

  ${down("sm")} {
    font: ${({ theme }) => theme.typography["label-small"]};
    width: 95px;
  }
`;
