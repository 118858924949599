import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { getMedia, useMediaQuery } from "utils/breakpoints";

import BinancePayCard from "./components/BinancePayCard";
import InvoiceCard from "./components/InvoiceCard";

import * as Styled from "./index.styled";

const BinancePay = () => {
  const params = useParams();
  const token = params.token!;

  const [searchParams] = useSearchParams();

  // required params
  const invoiceId = searchParams.get("invoice_id") as string;
  const amount = searchParams.get("amount") as string;
  const currency = searchParams.get("currency") as string;
  const qr = searchParams.get("qr") as string;
  const urlMobile = searchParams.get("url_mobile") as string;
  const urlWeb = searchParams.get("url_web") as string;

  // optional params
  const returnUrl = searchParams.get("return_url");
  const successUrl = searchParams.get("success_url");

  const isMobile = useMediaQuery(getMedia("md", "max"));

  return (
    <Styled.BinancePay>
      {isMobile && (
        <InvoiceCard id={invoiceId} sum={+amount} currency={currency} />
      )}
      <BinancePayCard
        invoiceId={invoiceId}
        amount={+amount}
        currency={currency}
        payMethodAlias={token}
        qr={qr}
        urlMobile={urlMobile}
        urlWeb={urlWeb}
        returnUrl={returnUrl ?? undefined}
        successUrl={successUrl ?? undefined}
      />
    </Styled.BinancePay>
  );
};

export default BinancePay;
