import React from "react";
import { useTranslation } from "react-i18next";

import * as Styled from "./index.styled";

const WrongParamsError = () => {
  const { t } = useTranslation("errors");

  return (
    <Styled.WrongParams>
      <Styled.StatusLabel label={t("wrongParams.label")} />
      {t("wrongParams.description")}
    </Styled.WrongParams>
  );
};

export default WrongParamsError;
