import { Card, List } from "@involve-software/uikit";
import styled from "styled-components";

export const InvoiceCard = styled(Card)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.primitives["s-24"]};
`;

export const Title = styled(Card.Title)`
  font-size: 18px;
`;

export const Heading = styled(Card.Heading)`
  margin-bottom: ${({ theme }) => theme.spacing.primitives["s-16"]};
`;

export const Content = styled(Card.Content)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-24"]};
`;

export const DetailsList = styled(List)`
  width: 100%;

  ul {
    gap: ${({ theme }) => theme.spacing.primitives["s-16"]};
  }
`;

export const DetailsItem = styled(List.Item)`
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.primitives["s-08"]};
  font: ${({ theme }) => theme.typography["label-medium"]};
`;

export const DetailsLabel = styled(List.Label)`
  min-width: auto;
`;

export const DetailsContent = styled(List.Content)``;

export const DetailsSumLabel = styled(DetailsLabel)`
  color: ${({ theme }) => theme.colors["text-primary"]};
`;

export const Sum = styled.div`
  text-align: right;
  font: ${({ theme }) => theme.typography["heading-large"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
`;
