import styled from "styled-components/macro";
import { Alert, Button, Card, Dialog } from "@involve-software/uikit";
import { activeTriggerCSS, boxCSS } from "./index.css";
import { down } from "utils/breakpoints";

export const CardTransferPayment = styled(Card)`
  margin: 0 auto;
  width: 100%;
  max-width: 495px;

  ${down("sm")} {
    border: none;
    padding: 0;
  }
`;

export const BankCardBlock = styled.div`
  box-shadow: 0 2px 20px -4px rgba(0, 0, 0, 0.2);
  //TODO add radius primitives exports in uikit
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors["border-main"]};
  padding: ${({ theme }) => theme.spacing.primitives["s-24"]}
    ${({ theme }) => theme.spacing.primitives["s-20"]};

  ${Card.Heading} {
    align-items: center;
  }
`;

export const BankCardHeadingTitle = styled(Card.HeadingTitle)`
  flex-direction: row;
  align-items: center;

  & > svg {
    flex-shrink: 0;
  }
`;

export const BankCardItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.primitives["s-20"]};
  }
`;

export const BankCardItemLabel = styled.p`
  font: ${({ theme }) => theme.typography["label-medium"]};
  color: ${({ theme }) => theme.colors["text-subtle"]};
  margin: 0 0 ${({ theme }) => theme.spacing.primitives["s-08"]};

  ${down("sm")} {
    font: ${({ theme }) => theme.typography["label-x-small"]};
  }
`;

export const BankCardItemValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: ${({ theme }) => theme.typography["label-large"]};
  color: ${({ theme }) => theme.colors["text-primary"]};

  svg {
    cursor: pointer;
  }

  ${down("sm")} {
    font: ${({ theme }) => theme.typography["label-medium"]};
  }
`;

export const InvoiceBlock = styled.div`
  ${boxCSS};
  margin: ${({ theme }) => theme.spacing.primitives["s-20"]} 0;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-08"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
  font: ${({ theme }) => theme.typography["heading-small"]};
`;

export const InvoiceTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.primitives["s-08"]};

  label {
    color: ${({ theme }) => theme.colors["text-subtle"]};
    font: ${({ theme }) => theme.typography["label-small"]};
  }

  p {
    color: ${({ theme }) => theme.colors["text-primary"]};
    font: ${({ theme }) => theme.typography["display-large"]};
    margin: 0;

    ${down("sm")} {
      font: ${({ theme }) => theme.typography["display-medium"]};
    }

    & > span {
      font: ${({ theme }) => theme.typography["label-small"]};
    }
  }
`;

export const AdditionalInfo = styled.div`
  ${boxCSS};

  & + & {
    margin-top: ${({ theme }) => theme.spacing.primitives["s-20"]};
  }
`;

export const AdditionalInfoList = styled.ul`
  margin: ${({ theme }) => theme.spacing.primitives["s-16"]} 0 0;
  padding: 0;
`;

export const AdditionalInfoItem = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.spacing.primitives["s-12"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
  font: ${({ theme }) => theme.typography["paragraph-medium"]};
  padding-left: ${({ theme }) => theme.spacing.primitives["s-06"]};

  & + & {
    margin-top: ${({ theme }) => theme.spacing.primitives["s-16"]};
  }

  p {
    margin: 0;
    b {
      font: ${({ theme }) => theme.typography["heading-x-small"]};
    }
  }
`;

export const AdditionalInfoTrigger = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${({ theme }) => theme.colors["text-primary"]};

  ${({ active }) => active && activeTriggerCSS};
`;

export const ButtonGroup = styled.div`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-28"]};

  a {
    text-decoration: none;
  }

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.primitives["s-12"]};
  }
`;

export const ConfirmPaymentDialog = styled(Dialog)`
  font: ${({ theme }) => theme.typography["paragraph-medium"]};
  color: ${({ theme }) => theme.colors["text-secondary"]};

  p {
    margin: ${({ theme }) => theme.spacing.primitives["s-08"]} 0;

    span {
      color: ${({ theme }) => theme.colors["text-primary"]};
      font: ${({ theme }) => theme.typography["label-medium"]};
    }
  }
`;

export const ConfirmPaymentButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
`;

export const WarningAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing.primitives["s-20"]};

  svg {
    width: 24px;
    height: 24px;
  }

  & > span:first-child {
    font: ${({ theme }) => theme.typography["heading-small"]};
  }

  & > span:last-child {
    font: ${({ theme }) => theme.typography["paragraph-medium"]};

    b {
      color: ${({ theme }) => theme.colors["text-danger"]};
      font: ${({ theme }) => theme.typography["heading-xs-small"]};
    }
  }
`;
