import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@involve-software/uikit";

import { useToggleSnackbar } from "utils/hooks/useToggleSnackbar";

import * as Styled from "./index.styled";

interface CopyBlockProps {
  copyText: string;
  label: string;
  startAdornment?: React.ReactNode;
}

const CopyBlock: FC<CopyBlockProps> = ({ copyText, label, startAdornment }) => {
  const { t } = useTranslation();
  const toggleSnackbar = useToggleSnackbar();
  const { icons } = useTheme();

  return (
    <Styled.CopyBlock>
      <Styled.CopyBlockLabel>{label}</Styled.CopyBlockLabel>
      <Styled.CopyField>
        <Styled.CopyBlockValue>
          {startAdornment}
          <span>{copyText}</span>
        </Styled.CopyBlockValue>
        <Styled.CopyBlockIcon>
          <icons.actions.copy.component
            onClick={() => {
              navigator.clipboard.writeText(copyText);

              toggleSnackbar({
                type: "success",
                message: t("common:copied"),
              });
            }}
          />
        </Styled.CopyBlockIcon>
      </Styled.CopyField>
    </Styled.CopyBlock>
  );
};

export default CopyBlock;
