import type { UseQueryOptions } from "@tanstack/react-query";

import { PaymentStatusData, getPaymentStatus } from "api/payment/status";
import { STATUS_UPDATE_PERIOD_MS } from "./data";
import { getQPaymentsSession, IQPaymentsSession } from "api/payment/qPayments";
import {
  getCardTransferSession,
  ICardTransferSession,
} from "api/payment/cardTransfer";

export const statusQuery = (
  queryData: {
    paymentId: string;
  },
  options?: UseQueryOptions<PaymentStatusData>
): UseQueryOptions<PaymentStatusData> => ({
  queryKey: ["calculation", queryData.paymentId],
  queryFn: async () => {
    const { data } = await getPaymentStatus(queryData.paymentId as string);
    return data;
  },
  refetchInterval: STATUS_UPDATE_PERIOD_MS,
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});

export const qPaymentsStatusQuery = (
  queryData: {
    paymentId: string;
  },
  options?: UseQueryOptions<IQPaymentsSession>
): UseQueryOptions<IQPaymentsSession> => ({
  queryKey: ["calculation", queryData.paymentId],
  queryFn: async () => {
    const { data } = await getQPaymentsSession(queryData.paymentId);
    return data;
  },
  refetchInterval: STATUS_UPDATE_PERIOD_MS,
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});

export const cardTransferStatusQuery = (
  queryData: {
    paymentId: string;
  },
  options?: UseQueryOptions<ICardTransferSession>
): UseQueryOptions<ICardTransferSession> => ({
  queryKey: ["calculation", queryData.paymentId],
  queryFn: async () => {
    const { data } = await getCardTransferSession(queryData.paymentId);
    return data;
  },
  refetchInterval: STATUS_UPDATE_PERIOD_MS,
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});
