import React from "react";

import * as Styled from "./index.styled";

interface OptionCardProps
  extends Omit<React.HTMLAttributes<HTMLButtonElement>, "title"> {
  icon: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const OptionCard: React.FC<OptionCardProps> = ({
  icon,
  title,
  subtitle,
  ...props
}) => (
  <Styled.OptionCard {...props}>
    <Styled.OptionIcon>{icon}</Styled.OptionIcon>
    {title && (
      <Styled.OptionText>
        <Styled.OptionName>{title}</Styled.OptionName>
        {subtitle && <Styled.OptionSubtitle>{subtitle}</Styled.OptionSubtitle>}
      </Styled.OptionText>
    )}
  </Styled.OptionCard>
);

export default OptionCard;
