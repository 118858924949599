import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "@involve-software/uikit";

import SuccessStatusLabel from "modules/result/components/StatusLabels/Success";

import { ActionButton } from "../index.styled";

const SuccessStatus = () => {
  const [searchParams] = useSearchParams();

  const successUrl = searchParams.get("successUrl");

  const { t } = useTranslation("result");

  return (
    <>
      <SuccessStatusLabel label={t("success.label")} />
      {successUrl && (
        <Link href={successUrl}>
          <ActionButton variant="outlined" fullWidth>
            {t("success.action")}
          </ActionButton>
        </Link>
      )}
    </>
  );
};

export default SuccessStatus;
