import { Dialog } from "@involve-software/uikit";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";
import { down } from "utils/breakpoints";

export const Container = styled.div``;

export const SelectDialog = styled(Dialog)`
  ${down("md")} {
     {
      /* TODO: Can be handled better on UIKit level */
    }
    width: 90%;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors["text-primary"]};
`;

export const Description = styled.p`
  margin: 0;
  font: ${({ theme }) => theme.typography["paragraph-medium"]};
   {
    /* TODO: Update color in UIKit to follow design system */
  }
  color: ${({ theme }) => theme.colors["text-secondary"]};
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.primitives["s-24"]};
  gap: ${({ theme }) => theme.spacing.primitives["s-12"]};
`;

export const Skeletons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-12"]};
`;

export const OptionSkeleton = styled(Skeleton)`
  height: 50px;
`;
export const ErrorText = styled.span`
  font: ${({ theme }) => theme.typography["paragraph-small"]};
`;
