import React from "react";
import { useTheme } from "@involve-software/uikit";

import { LanguageDropdown } from "components/LanguageDropdown";

import * as Styled from "./index.styled";

const Header = () => {
  const { images } = useTheme();

  return (
    <Styled.Header>
      <Styled.HeaderLogo>
        <images.logo.component />
      </Styled.HeaderLogo>
      <LanguageDropdown />
    </Styled.Header>
  );
};

export default Header;
