import styled from "styled-components";
import { LanguageSelect } from "components/LanguageDropdown/index.styled";
import { down } from "utils/breakpoints";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.primitives["s-20"]} 0;
  max-width: 920px;
  width: 100%;

  ${LanguageSelect} {
    margin-left: auto;
  }
`;

export const HeaderLogo = styled.div`
  & > svg {
    height: 100%;
  }

  ${down("sm")} {
    height: 44px;
  }
`;
