import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import type { PaymentSession } from "api/payment";

export const PaymentContext = React.createContext<PaymentSession>(
  null as unknown as PaymentSession
);

const PaymentLayout = () => {
  const paymentSession = useLoaderData() as PaymentSession;

  return (
    <PaymentContext.Provider value={paymentSession}>
      <Outlet />
    </PaymentContext.Provider>
  );
};

export default PaymentLayout;
