import React, { useContext } from "react";
import { useLoaderData } from "react-router-dom";

import type { CryptoDetailsLoaderData } from "modules/crypto/loaders";
import PaymentDetails from "modules/crypto/components/PaymentDetails";
import { CryptoPaymentContext } from "modules/crypto/layout";
import InvoiceCard from "modules/crypto/components/InvoiceCard";

import * as Styled from "./index.styled";

export const CryptoDetails: React.FC = () => {
  const session = useContext(CryptoPaymentContext);

  const { paywaysData, paywayData } =
    useLoaderData() as CryptoDetailsLoaderData;

  const initialBlockchain = paywayData
    ? {
        id: paywayData?.id,
        name: paywayData?.paymethodName as string,
        currency: paywayData?.currency,
        alias: paywayData?.alias,
      }
    : null;

  return (
    <Styled.CryptoDetails>
      <InvoiceCard shopName={session.shopName} />
      <PaymentDetails
        paymethodId={(session.paymethodId ?? paywayData?.paymethodId) as number}
        sessionPaymethodId={session.paymethodId}
        paymethodName={
          paywaysData?.name ?? (paywayData?.paymethodName as string)
        }
        initialValues={{
          email: session.email ?? "",
          blockchain:
            paywaysData?.payways.length === 1
              ? paywaysData?.payways[0]
              : initialBlockchain,
        }}
      />
    </Styled.CryptoDetails>
  );
};

export default CryptoDetails;
