import { factory, getAuthorization } from "api/base";
import { RequestMethod } from "utils/constants";

export enum PaymentStatus {
  SUCCESS = 1,
  REJECTED = 2,
  IN_PROGRESS = 3,
}

export interface PaymentStatusData {
  status: PaymentStatus;
  successUrl?: string;
  failedUrl?: string;
}

export const getPaymentStatus = factory(
  (r) => (paymentId: string) =>
    r<PaymentStatusData>(
      RequestMethod.get,
      `/payment/status/${paymentId}`,
      undefined,
      {
        headers: getAuthorization(paymentId),
      }
    )
);
