import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import WrongParamsError from "components/Errors/WrongParams";
import CardTransferPayment from "./pages/CardTransferPayment";

import { cardTransferLoader } from "./loaders";

export default (
  <Route
    path="card_transfer/:paymentId"
    loader={cardTransferLoader}
    element={<CardTransferPayment />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  />
);
