import styled from "styled-components";
import { Alert } from "@involve-software/uikit";

import Accordion from "modules/payment/components/Accordion";
import { Skeleton } from "components/Skeleton";

export const InfoAccordion = styled(Accordion)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
`;

export const ManualAccordion = styled(Accordion)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
`;

export const InfoLoadingSkeleton = styled(Skeleton)`
  width: 100%;
  height: 75px;
`;

export const BottomLoadingSkeleton = styled(InfoLoadingSkeleton)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
`;

export const TopInfoLoadingSkeleton = styled(InfoLoadingSkeleton)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-20"]};
`;

export const TopAlert = styled(Alert)`
  width: 100%;
`;

export const InfoBlocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-10"]};
  width: 100%;
`;
