import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import WrongParamsError from "components/Errors/WrongParams";
import QPayments from "./pages/QPayments";

import { qPaymentsLoader } from "./loaders";

export default (
  <Route
    path="qpayments/:paymentId"
    loader={qPaymentsLoader}
    element={<QPayments />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  />
);
