import React from "react";

import "nprogress/nprogress.css";

import useNavigationLoadingBar from "utils/hooks/useNavigationLoadingBar";
import Header from "./Header";
import Footer from "./Footer";

import * as Styled from "./index.styled";

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  useNavigationLoadingBar();

  return (
    <Styled.Container>
      <Header />
      <Styled.Content>{children}</Styled.Content>
      <Footer />
    </Styled.Container>
  );
};

export default Layout;
