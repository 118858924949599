import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import {
  redirectLoader,
  paymentLoader,
  methodsLoader,
  detailsLoader,
} from "./loaders";
import PaymentDetailsLayout from "modules/payment/pages/details/layout";
import InvoiceStepsLayout from "./components/InvoiceStepsLayout";
import WrongParamsError from "components/Errors/WrongParams";
import PaymentMethods from "./pages/methods";
import PaymentDetails from "./pages/details";
import AutoTypePayment from "./pages/auto";
import PaymentLayout from "./layout";

export default (
  <Route
    path="payment/:paymentId"
    loader={paymentLoader}
    element={<PaymentLayout />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  >
    <Route path="" loader={redirectLoader} />
    <Route path="auto" element={<AutoTypePayment />} />
    <Route element={<InvoiceStepsLayout />} errorElement={<WrongParamsError />}>
      <Route
        path="methods"
        loader={methodsLoader}
        element={<PaymentMethods />}
      />
      <Route
        path="details"
        loader={detailsLoader}
        element={<PaymentDetails />}
        errorElement={
          <PaymentDetailsLayout>
            <WrongParamsError />
          </PaymentDetailsLayout>
        }
      />
    </Route>
  </Route>
);
