import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { getMedia, useMediaQuery } from "utils/breakpoints";

import QrPaymentCard from "./components/QrPaymentCard";
import InvoiceCard from "./components/InvoiceCard";

import * as Styled from "./index.styled";

const QrPayment = () => {
  const params = useParams();
  const payMethodAlias = params.payMethodAlias as string;

  const [searchParams] = useSearchParams();

  const invoiceId = searchParams.get("invoice_id") as string;
  const amount = searchParams.get("amount") as string;
  const currency = searchParams.get("currency") as string;
  const qr = searchParams.get("qr") as string;
  const returnUrl = searchParams.get("return_url") as string;

  const isMobile = useMediaQuery(getMedia("md", "max"));

  return (
    <Styled.QrPayment>
      {isMobile && (
        <InvoiceCard id={invoiceId} sum={+amount} currency={currency} />
      )}
      <QrPaymentCard
        invoiceId={invoiceId}
        amount={+amount}
        currency={currency}
        payMethodAlias={payMethodAlias}
        qr={qr}
        returnUrl={returnUrl}
      />
    </Styled.QrPayment>
  );
};

export default QrPayment;
