import React from "react";
import { useTranslation } from "react-i18next";

import type { CryptoAddress as CryptoAddressType } from "api/crypto/payment";

import * as Styled from "./index.styled";

interface CryptoAddressProps extends React.HTMLAttributes<HTMLDivElement> {
  address: CryptoAddressType["data"]["address"];
  actions: React.ReactNode;
}

const CryptoAddress: React.FC<CryptoAddressProps> = ({
  address,
  actions,
  ...props
}) => {
  const { t } = useTranslation("crypto");

  return (
    <Styled.Address {...props}>
      <Styled.AddressTitle>{t("payment.address.title")}</Styled.AddressTitle>
      <Styled.AddressContent>
        <Styled.AddressValue>{address}</Styled.AddressValue>
        <Styled.AddressActions>{actions}</Styled.AddressActions>
      </Styled.AddressContent>
    </Styled.Address>
  );
};

export default CryptoAddress;
