import React, { useContext } from "react";
import { Link, List, useTheme } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";

import type { DetailsData } from "modules/crypto/data/loader-data";
import CopyActionButton from "../CryptoAddress/ActionButtons/Copy";
import QrActionButton from "../CryptoAddress/ActionButtons/Qr";
import { CryptoPaymentContext } from "modules/crypto/layout";
import { getMedia, useMediaQuery } from "utils/breakpoints";
import SideInfoSection from "../SideInfoSection";
import CryptoAddress from "../CryptoAddress";
import { getPmImgSrc } from "utils/media";

import * as Styled from "./index.styled";

type DetailsSectionProps = {
  detailsData: DetailsData;
};

const DetailsSection: React.FC<DetailsSectionProps> = ({ detailsData }) => {
  const session = useContext(CryptoPaymentContext);

  const isMobile = useMediaQuery(getMedia("md", "max"));

  const { t } = useTranslation("crypto");

  const { icons } = useTheme();

  const scannerLink = detailsData.payway.scannerUrl.replace(
    "{address}",
    detailsData.address.address
  );

  return (
    <Styled.Details>
      <Styled.Title>{t("payment.title")}</Styled.Title>
      <Styled.DetailsList>
        <List.Item>
          <Styled.ItemLabel>{t("payment.receiver")}</Styled.ItemLabel>
          <Styled.ItemContent>{session.shopName}</Styled.ItemContent>
        </List.Item>
        <List.Item>
          <Styled.ItemLabel>{t("payment.payMethod")}</Styled.ItemLabel>
          <Styled.PaymentMethodContent>
            <Styled.PayMethodIcon>
              <img
                src={getPmImgSrc(detailsData.payway.paymethodId, true)}
                alt={t("payment.payMethod")}
              />
            </Styled.PayMethodIcon>
            {detailsData.payway.paymethodName}
          </Styled.PaymentMethodContent>
        </List.Item>
        <List.Item>
          <Styled.ItemLabel>{t("payment.blockchain")}</Styled.ItemLabel>
          <Styled.ItemContent>
            {detailsData.payway.paymethodName}&nbsp;
            <Styled.BlockchainDetails>
              ({detailsData.payway.name.replace(/[()]/g, "")})
            </Styled.BlockchainDetails>
          </Styled.ItemContent>
        </List.Item>
        <List.Item>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Styled.ItemLabel>Email</Styled.ItemLabel>
          <Styled.ItemContent>{detailsData.email}</Styled.ItemContent>
        </List.Item>
      </Styled.DetailsList>
      <Styled.AddressDetails>
        <Styled.AddressInfo>
          <Styled.MinDeposit>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {t("payment.address.minDeposit")} - {detailsData.payway.minAmount}{" "}
            {detailsData.payway.paymethodName}
          </Styled.MinDeposit>
          <CryptoAddress
            address={detailsData.address.address}
            actions={
              <>
                <CopyActionButton copyText={detailsData.address.address} />
                <QrActionButton address={detailsData.address.address} />
              </>
            }
          />
        </Styled.AddressInfo>
        {isMobile && <SideInfoSection infoText={detailsData.infoText} />}
        <Styled.ActionButtons>
          <Link href={scannerLink} target="_blank">
            <Styled.ActionButton fullWidth>
              {t("payment.actions.trackTransfer")}
            </Styled.ActionButton>
          </Link>
          {session.returnUrl && (
            <Link href={session.returnUrl}>
              <Styled.ActionButton
                icon={<icons.outerLink.component />}
                variant="text"
                fullWidth
              >
                {t("payment.actions.toShop")}
              </Styled.ActionButton>
            </Link>
          )}
        </Styled.ActionButtons>
      </Styled.AddressDetails>
    </Styled.Details>
  );
};

export default DetailsSection;
