import styled from "styled-components";

export const IconButton = styled.button`
  width: 24px;
  height: 24px;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }
`;
