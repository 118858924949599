import { factory, getAuthorization } from "api/base";
import { RequestMethod } from "utils/constants";

export interface IPaycosSession {
  amount: number;
  card: string;
  cardHolder: string;
  currency: {
    alias: string;
    code: number;
  };
  description: string | null;
  paymentId: string;
  returnUrl: string | null;
  successUrl: string | null;
}

export const getPaycosSession = factory(
  (r) => (session: string) =>
    r<IPaycosSession>(RequestMethod.get, `/p2p_pcs/session`, undefined, {
      headers: getAuthorization(session),
    })
);
