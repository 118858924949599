import React from "react";

import * as Styled from "./index.styled";

interface StatusLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  image: React.ReactNode;
  label: string;
}

const StatusLabel: React.FC<StatusLabelProps> = ({
  image,
  label,
  ...props
}) => (
  <Styled.StatusLabel {...props}>
    <Styled.Image>{image}</Styled.Image>
    <Styled.Label>{label}</Styled.Label>
  </Styled.StatusLabel>
);

export default StatusLabel;
