import { useTranslation } from "react-i18next";
import { FALLBACK_LNG } from "data/i18n";

export function usePayMethodName({
  transObj = null,
  defaultValue = "",
}: {
  transObj?: Record<string, string> | null;
  defaultValue?: string;
}) {
  const {
    i18n: { language },
  } = useTranslation();

  const transValue = transObj?.[language] ?? transObj?.[FALLBACK_LNG];
  return transValue ?? defaultValue;
}
