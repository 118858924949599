import styled from "styled-components";

export const StatusLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-28"]};
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Label = styled.div`
  font: ${({ theme }) => theme.typography["heading-large"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
  text-align: center;
`;
