import { type LoaderFunction } from "react-router-dom";

import { REQUIRED_QR_PARAMS } from "./data/params";

export const qrPaymentLoader: LoaderFunction = async ({ params, request }) => {
  const { searchParams } = new URL(request.url);

  if (
    !params.payMethodAlias ||
    REQUIRED_QR_PARAMS.some((param) => !searchParams.has(param))
  ) {
    throw new Error("Required params are missing");
  }

  return null;
};
