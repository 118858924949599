import { factory, getAuthorization } from "api/base";
import { RequestMethod } from "utils/constants";

export interface CryptoPaymentInfo {
  name: string;
  text: {
    en: string;
    pl: string;
    ru: string;
    uk: string;
  };
}

export type CryptoPaymentInfoType = "info" | "warning" | "manual";

export const getCryptoPaymentInfo = factory(
  (r) => (type: CryptoPaymentInfoType, infoId: number, paymentId: string) =>
    r<CryptoPaymentInfo>(
      RequestMethod.get,
      `/crypto/${type}/${infoId}`,
      undefined,
      {
        headers: getAuthorization(paymentId),
      }
    )
);
