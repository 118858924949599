import styled, { css } from "styled-components";

const variantCss = {
  info: css`
    background: ${({ theme }) => theme.colors["background-info"]};
  `,
  warning: css`
    background: ${({ theme }) => theme.colors["background-warning"]};
  `,
};

// TODO: Move warning color to theme
export const Alert = styled.div<{ $variant: "info" | "warning" }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.primitives["s-12"]}
    ${({ theme }) => theme.spacing.primitives["s-20"]};
  gap: ${({ theme }) => theme.spacing.primitives["s-16"]};
  border-radius: ${({ theme }) => theme.radius["radius-pr-strong"]};

  ${({ $variant }) => variantCss[$variant]};
`;

export const AlertIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AlertTitle = styled.div`
  font: ${({ theme }) => theme.typography["heading-x-small"]};
`;

export const AlertDescription = styled.div`
  font: ${({ theme }) => theme.typography["paragraph-x-small"]};
`;
