import { useEffect, useState } from "react";

// TODO: Move breakpoints util to theme configuration in UIKit
export const breakpoints = {
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
};

export type Breakpoint = keyof typeof breakpoints;

export const getMedia = (breakpoint: Breakpoint, type: "max" | "min") =>
  `(${type}-width: ${breakpoints[breakpoint] - +(type === "max")}px)`;

export const down = (breakpoint: Breakpoint) =>
  `@media ${getMedia(breakpoint, "max")}`;

export const up = (breakpoint: Breakpoint) =>
  `@media ${getMedia(breakpoint, "min")}`;

export const useMediaQuery = (media: string) => {
  const [matches, setMatches] = useState(() => {
    const { matches } = window.matchMedia(media);
    return matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia(media);
    const listener = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    mediaQuery.addEventListener("change", listener);
    return () => mediaQuery.removeEventListener("change", listener);
  }, [media]);

  return matches;
};
