import { Card } from "@involve-software/uikit";
import styled from "styled-components";

import { down } from "utils/breakpoints";

export const StatusCard = styled(Card)`
  width: 100%;
  max-width: 500px;
  margin: auto;

  ${down("sm")} {
    padding: ${({ theme }) => theme.spacing.primitives["s-28"]};
    ${({ theme }) => theme.spacing.primitives["s-24"]};
  }
`;

export const Content = styled(Card.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${down("sm")} {
    padding: 0;
  }
`;
