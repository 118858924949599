import { type LoaderFunction } from "react-router-dom";

import { REQUIRED_BINANCEPAY_PARAMS } from "./data/params";
import { changeLanguage } from "utils/language";
import type { Language } from "data/i18n";

export const binancePayLoader: LoaderFunction = async ({ params, request }) => {
  const { searchParams } = new URL(request.url);

  if (REQUIRED_BINANCEPAY_PARAMS.some((param) => !searchParams.has(param))) {
    throw new Error("Required params are missing");
  }

  if (searchParams.get("lng")) {
    await changeLanguage(searchParams.get("lng") as Language);
  }
  return null;
};
