import styled from "styled-components/macro";
import { Card } from "@involve-software/uikit";

export const ExpiryStatusBlock = styled(Card)`
  text-align: center;
  width: 100%;
  max-width: 502px;
  margin: auto;

  & > p {
    font: ${({ theme }) => theme.typography["label-large"]};
    margin: ${({ theme }) => theme.spacing.primitives["s-08"]} 0 0;
  }

  & > svg {
    margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
  }

  a {
    display: block;
    margin-top: ${({ theme }) => theme.spacing.primitives["s-56"]};
    text-decoration: none;
  }
`;
