import styled from "styled-components";

import PaymentDetailsForm from "modules/payment/components/PaymentDetailsForm";
import Accordion from "modules/payment/components/Accordion";
import Alert from "modules/payment/components/Alert";
import { Skeleton } from "components/Skeleton";
import { down } from "utils/breakpoints";

export const DetailsForm = styled(PaymentDetailsForm)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-20"]};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${down("sm")} {
    margin-bottom: ${({ theme }) => theme.spacing.primitives["s-24"]};
  }
`;

export const InfoAccordion = styled(Accordion)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
`;

export const ManualAccordion = styled(Accordion)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
`;

export const InfoLoadingSkeleton = styled(Skeleton)`
  width: 100%;
  height: 75px;
`;

export const ButtomLoadingSkeleton = styled(InfoLoadingSkeleton)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
`;

export const TopInfoLoadingSkeleton = styled(InfoLoadingSkeleton)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-20"]};
`;

export const TopAlert = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing.primitives["s-20"]};
`;
