import styled from "styled-components/macro";
import { css } from "styled-components";

export const LanguageSelect = styled.div`
  position: relative;
  display: inline-block;
  text-align: right;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-08"]};

  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
`;

export const LanguageDropdownHeader = styled.div<{ $isOpen: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
  cursor: pointer;
  font: ${({ theme }) => theme.typography["label-small"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
  padding: ${({ theme }) => theme.spacing.primitives["s-04"]};
  border: 1px solid transparent;
  border-radius: 4px;
  transition: 0.3s ease border-color;

  &:hover {
    border-color: ${({ theme }) => theme.colors["button-primary"]};
  }

  & > svg {
    transition: 0.3s ease transform;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      border-color: ${({ theme }) => theme.colors["button-primary"]};
      & > svg {
        transform: rotate(-180deg);
      }
    `}
`;
