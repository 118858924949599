import React from "react";
import { Card, useTheme } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";

import { getMedia, useMediaQuery } from "utils/breakpoints";
import { payMethodQrLogos } from "../../data";

import * as Styled from "./index.styled";

interface QrPaymentCardProps {
  amount: number;
  currency: string;
  invoiceId: string;
  payMethodAlias: string;
  qr: string;
  returnUrl: string;
}

const QrPaymentCard: React.FC<QrPaymentCardProps> = ({
  amount,
  currency,
  invoiceId,
  payMethodAlias,
  qr,
  returnUrl,
}) => {
  const { t } = useTranslation("qrPay");

  const isDesktop = useMediaQuery(getMedia("md", "min"));

  const { icons } = useTheme();

  return (
    <Styled.QrCard>
      <Styled.QrHeading>
        <Card.HeadingTitle>
          <Styled.QrTitle>
            {isDesktop ? (
              <>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {t("common:invoice")} №{invoiceId}
              </>
            ) : (
              t("payment")
            )}
          </Styled.QrTitle>
        </Card.HeadingTitle>
        {isDesktop && (
          <Styled.QrHeadingActions>
            <Styled.Amount>
              {amount} {currency}
            </Styled.Amount>
            <Styled.AmountLabel>{t("amount")}</Styled.AmountLabel>
          </Styled.QrHeadingActions>
        )}
      </Styled.QrHeading>
      {payMethodQrLogos[payMethodAlias] && (
        <Styled.QrMethodLogo>
          {payMethodQrLogos[payMethodAlias]}
        </Styled.QrMethodLogo>
      )}
      <Styled.QrCode>
        <p>{t("description")}</p>
        <img
          src={`data:image/png;base64,${decodeURIComponent(qr)}`}
          alt="qr-code"
        />
      </Styled.QrCode>
      {returnUrl && (
        <Styled.SuccessLink href={returnUrl}>
          <Styled.SuccessButton>
            <icons.statuses.success.component /> {t("paymentDone")}
          </Styled.SuccessButton>
        </Styled.SuccessLink>
      )}
    </Styled.QrCard>
  );
};

export default QrPaymentCard;
