import { type LoaderFunction } from "react-router-dom";

import { getEmanatSession } from "api/payment/emanat";

export const emanatLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error("Required params are missing");
  }

  const { data } = await getEmanatSession(params.paymentId);

  return data;
};
