import { type LoaderFunction } from "react-router-dom";

export const paymentStatusLoader: LoaderFunction = async ({
  params,
  request,
}) => {
  const { searchParams } = new URL(request.url);

  if (!params.paymentId || !searchParams.get("status")) {
    throw new Error("invalid params");
  }

  return null;
};
