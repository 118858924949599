import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import WrongParamsError from "components/Errors/WrongParams";
import QrPayment from "./pages/QrPayment";

import { qrPaymentLoader } from "./loaders";

export default (
  <Route
    path="qr-pay/:payMethodAlias"
    loader={qrPaymentLoader}
    element={<QrPayment />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  />
);
