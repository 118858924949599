import { factory, getAuthorization } from "api/base";
import { RequestMethod } from "utils/constants";

export interface PaymentMethod {
  id: number;
  name: string;
  nameTranslations: Record<string, string> | null;
  paywayCurrency: number;
  paywayAlias: string;
  payerPrice: number;
  calculationNeeded: boolean;
}

export const getPaymentMethods = factory(
  (r) => (paymentId: string) =>
    r<PaymentMethod[]>(RequestMethod.get, "/paymethods", undefined, {
      headers: getAuthorization(paymentId),
    })
);

export interface PaymentPayway {
  currency: number;
  name: string;
}

export const getPaymentPayways = factory(
  (r) => (paymentId: string, paymethodId: number) =>
    r<PaymentPayway[]>(
      RequestMethod.get,
      `/paymethods/${paymethodId}/payways`,
      undefined,
      {
        headers: getAuthorization(paymentId),
      }
    )
);

export const getPaymentPayway = factory(
  (r) => (paymentId: string, paywayId: string | number) =>
    r<PaymentPayway[]>(
      RequestMethod.get,
      `/paymethods/${paywayId}/payway`,
      undefined,
      {
        headers: getAuthorization(paymentId),
      }
    )
);
