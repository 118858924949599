import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id: NodeJS.Timer;
    function tick() {
      if (savedCallback.current == null) return;
      savedCallback.current();
    }
    if (delay !== null) {
      id = setInterval(tick, delay);
    }
    return () => {
      if (id) clearInterval(id);
    };
  }, [delay]);
}
