import React from "react";
import { useLoaderData } from "react-router-dom";

import { IPlatipaySession } from "api/payment/platipay";
import InvoiceCard from "./components/InvoiceCard";
import PlatipayCard from "./components/PlatipayCard";

import * as Styled from "./index.styled";

const PlatipayPayment = () => {
  const {
    amount,
    card,
    currency,
    description,
    returnUrl,
    successUrl,
    paymentId,
  } = useLoaderData() as IPlatipaySession;

  return (
    <Styled.PlatipayPayment>
      <InvoiceCard
        id={paymentId}
        sum={amount}
        currency={currency.alias}
        description={description}
      />
      <PlatipayCard returnUrl={returnUrl} successUrl={successUrl} card={card} />
    </Styled.PlatipayPayment>
  );
};

export default PlatipayPayment;
