import { type LoaderFunction } from "react-router-dom";

import { getPaycosSession } from "api/payment/paycos";

export const paycosLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error("Required params are missing");
  }

  const { data } = await getPaycosSession(params.paymentId);

  return data;
};
