import React from "react";
import { Card } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";

import * as Styled from "./index.styled";
import { getMedia, useMediaQuery } from "utils/breakpoints";

interface InvoiceCardProps {
  id: string;
  sum: number;
  currency: string;
  description: string | null;
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({
  id,
  sum,
  currency,
  description,
}) => {
  const { t } = useTranslation("cactusPay");

  const isDesktop = useMediaQuery(getMedia("md", "min"));

  return (
    <Styled.InvoiceCard>
      <Styled.Heading>
        <Card.HeadingTitle>
          <Styled.Title>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {t("common:invoice")} №{id}
          </Styled.Title>
        </Card.HeadingTitle>
      </Styled.Heading>
      <Styled.Content>
        <Styled.DetailsList>
          {!isDesktop && (
            <Styled.DetailsItem>
              <Styled.PaymentSumInfo>
                <Styled.Sum>
                  {sum} {currency}
                </Styled.Sum>
                <Styled.SumSubtitle>{t("common:amount")}</Styled.SumSubtitle>
              </Styled.PaymentSumInfo>
            </Styled.DetailsItem>
          )}
          {description && (
            <Styled.DetailsItem>
              <Styled.DetailsLabel>{t("common:comment")}</Styled.DetailsLabel>
              <Styled.DetailsContent>{description}</Styled.DetailsContent>
            </Styled.DetailsItem>
          )}
        </Styled.DetailsList>
        {isDesktop && (
          <Styled.PaymentSumInfo>
            <Styled.Sum>
              {sum} {currency}
            </Styled.Sum>
            <Styled.SumSubtitle>{t("common:amount")}</Styled.SumSubtitle>
          </Styled.PaymentSumInfo>
        )}
      </Styled.Content>
    </Styled.InvoiceCard>
  );
};

export default InvoiceCard;
