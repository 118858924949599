import React, { useContext, useEffect, useState } from "react";
import { Alert, Dialog } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";

import type { CryptoAddress as CryptoAddressType } from "api/crypto/payment";
import CopyActionButton from "../CryptoAddress/ActionButtons/Copy";
import { CryptoPaymentContext } from "modules/crypto/layout";
import { qrCode } from "modules/crypto/data/qr";
import CryptoAddress from "../CryptoAddress";
import { getPmImgSrc } from "utils/media";

import * as Styled from "./index.styled";

interface QrPaymentDialogProps
  extends Omit<React.ComponentProps<typeof Dialog>, "title" | "children"> {
  address: CryptoAddressType["data"]["address"];
}

const QrPaymentDialog: React.FC<QrPaymentDialogProps> = ({
  open,
  onClose,
  address,
}) => {
  const [qrContainer, setQrContainer] = useState<HTMLDivElement | null>();

  const { paymethodId } = useContext(CryptoPaymentContext);

  const { t } = useTranslation("crypto");

  useEffect(() => {
    if (qrContainer) {
      qrCode.append(qrContainer);
    }
  }, [qrContainer]);

  useEffect(() => {
    qrCode.update({
      data: address,
      image: getPmImgSrc(+(paymethodId as string), true),
    });
  }, [paymethodId, address]);

  const warningItems = t("payment.qr.alerts.warning", {
    returnObjects: true,
  }) as string[];

  return (
    <Styled.QrPayDialog
      title={<Styled.Title>{t("payment.qr.title")}</Styled.Title>}
      open={open}
      onClose={onClose}
    >
      <Styled.Content>
        <Styled.Description>{t("payment.qr.description")}</Styled.Description>
        <div ref={setQrContainer} />
        <CryptoAddress
          address={address}
          actions={<CopyActionButton copyText={address} />}
        />
        <Alert title={t("common:alerts.important")} variant="warning">
          <ul>
            {typeof warningItems === "object" &&
              warningItems.map((text) => <li key={text}>{text}</li>)}
          </ul>
        </Alert>
      </Styled.Content>
    </Styled.QrPayDialog>
  );
};

export default QrPaymentDialog;
