import styled, { css } from "styled-components/macro";

export const Stepper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Connector = styled.div<{ $isActive: boolean }>`
  position: relative;
  width: 150px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors["layer-02"]};

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.colors["layer-brand-strong"]};
    `}
`;

const activeCss = css`
  box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colors["border-brand"]};
  background-color: ${({ theme }) => theme.colors["layer-brand-subtle"]};
  color: ${({ theme }) => theme.colors["text-brand"]};
`;

export const Step = styled.div<{
  $isActive?: boolean;
  $isFinished?: boolean;
  $label: string;
}>`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors["border-main"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
  background-color: ${({ theme }) => theme.colors["layer-01"]};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font: ${({ theme }) => theme.typography["label-medium"]};

  &:before {
    content: "${({ $label }) => $label}";
    white-space: nowrap;
    position: absolute;
    top: calc(100% + ${({ theme }) => theme.spacing.primitives["s-08"]});
    font: ${({ theme }) => theme.typography["label-small"]};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      ${activeCss};
    `}

  ${({ $isFinished }) =>
    $isFinished &&
    css`
      ${activeCss};

      background-color: ${({ theme }) => theme.colors["fill-02"]};
    `}
`;
