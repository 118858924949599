import React from "react";
import { Input } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";

import type { Language } from "data/i18n";
import { PaymentAddsOnConfig } from "api/payment/calculation";

interface AddOnsInputProps extends React.ComponentProps<typeof Input> {
  name: string;
  config: PaymentAddsOnConfig;
}

const AddOnsInput: React.FC<AddOnsInputProps> = ({
  name,
  config,
  ...props
}) => {
  const { i18n } = useTranslation();

  return (
    <Input
      // TODO: Fix in uikit types
      // @ts-ignore
      name={name}
      helperText={config.comment?.[i18n.language as Language]}
      placeholder={config.example}
      {...props}
    />
  );
};

export default AddOnsInput;
