import styled from "styled-components";

export const ManualAlertTrigger = styled.div`
  color: ${({ theme }) => theme.colors["text-subtle"]};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
  width: fit-content;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export const DialogContent = styled.div`
  color: ${({ theme }) => theme.colors["text-primary"]};
`;
