import styled from "styled-components";

import { down } from "utils/breakpoints";

export const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.primitives["s-16"]}
    ${({ theme }) => theme.spacing.primitives["s-16"]};

  ${down("md")} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${({ theme }) => theme.spacing.primitives["s-12"]};
  }

  ${down("sm")} {
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.spacing.primitives["s-12"]};
  }
`;
