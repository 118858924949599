import React, { Children, cloneElement } from "react";
import { useTheme } from "@involve-software/uikit";

import * as Styled from "./index.styled";

interface StepperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  activeStep?: number;
}

const Stepper: React.FC<StepperProps> & { Step: typeof Styled.Step } = ({
  children,
  activeStep = -1,
  ...props
}) => {
  const { icons } = useTheme();

  const arrayChildren = Children.toArray(children);

  return (
    <Styled.Stepper {...props}>
      {Children.map(arrayChildren, (child, index) => {
        return (
          <>
            {cloneElement(child as React.ReactElement, {
              $isActive: index === activeStep,
              $isFinished: index < activeStep,
              children: index < activeStep ? <icons.checkRound.component /> : index + 1,
            })}
            {index !== arrayChildren.length - 1 && (
              <Styled.Connector $isActive={index < activeStep} />
            )}
          </>
        );
      })}
    </Styled.Stepper>
  );
};

Stepper.Step = Styled.Step;

export default Stepper;
