import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as MasterCardImage } from "assets/images/verified/mastercard.svg";
import { ReactComponent as VisaVerifiedImage } from "assets/images/verified/visa.svg";
import { ReactComponent as PciDssImage } from "assets/images/verified/pci_dss.svg";

import * as Styled from "./index.styled";
import { Button } from "@involve-software/uikit";
import { env } from "utils/env";

const Footer = () => {
  const { t } = useTranslation("common");

  return (
    <Styled.Footer>
      <Styled.SecureIcons>
        <VisaVerifiedImage />
        <MasterCardImage />
        <PciDssImage />
      </Styled.SecureIcons>
      {env.REACT_APP_SUPPORT_MAIL && (
        <Styled.SupportLink to={`mailto:${env.REACT_APP_SUPPORT_MAIL}`}>
          <Button variant="text">{t("common:contacts")}</Button>
        </Styled.SupportLink>
      )}
    </Styled.Footer>
  );
};

export default Footer;
