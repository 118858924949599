import React from "react";
import { useSearchParams } from "react-router-dom";

import InProgressStatus from "modules/result/components/StatusBlock/InProgressStatus";
import SuccessStatus from "modules/result/components/StatusBlock/SuccessStatus";
import ErrorStatus from "modules/result/components/StatusBlock/ErrorStatus";
import WrongParamsError from "components/Errors/WrongParams";
import { PaymentStatus } from "api/payment/status";

import QPaymentsInProgressStatus from "modules/result/components/StatusBlock/InProgressStatus/QPaymentsInProgressStatus";

import CardTransferInProgressStatus from "modules/result/components/StatusBlock/InProgressStatus/CardTransferInProgressStatus";
import CardTransferSuccessStatus from "modules/result/components/StatusBlock/SuccessStatus/CardTransferSuccessStatus";
import CardTransferFailedStatus from "modules/result/components/StatusBlock/ErrorStatus/CardTransferFailedStatus";

import CactusBanksErrorStatus from "modules/result/components/StatusBlock/ErrorStatus/CactusBanksFailedStatus";
import { PaymentsType } from "types/payment";

import * as Styled from "./index.styled";

type StatusLabel = Record<PaymentStatus, React.ReactNode>;

const typeLabels: Record<PaymentsType, StatusLabel> = {
  [PaymentsType.INITIAL]: {
    [PaymentStatus.SUCCESS]: <SuccessStatus />,
    [PaymentStatus.REJECTED]: <ErrorStatus />,
    [PaymentStatus.IN_PROGRESS]: <InProgressStatus />,
  },
  [PaymentsType.QPAYMENTS]: {
    [PaymentStatus.SUCCESS]: <SuccessStatus />,
    [PaymentStatus.REJECTED]: <ErrorStatus />,
    [PaymentStatus.IN_PROGRESS]: <QPaymentsInProgressStatus />,
  },
  [PaymentsType.CACTUS]: {
    [PaymentStatus.SUCCESS]: <SuccessStatus />,
    [PaymentStatus.REJECTED]: <CactusBanksErrorStatus />,
    [PaymentStatus.IN_PROGRESS]: <InProgressStatus />,
  },
  [PaymentsType.CARD_TRANSFER]: {
    [PaymentStatus.SUCCESS]: <CardTransferSuccessStatus />,
    [PaymentStatus.REJECTED]: <CardTransferFailedStatus />,
    [PaymentStatus.IN_PROGRESS]: <CardTransferInProgressStatus />,
  },
};

const PaymentResult = () => {
  const [searchParams] = useSearchParams();

  const status = searchParams.get("status") as string;
  const type = searchParams.get("type") as PaymentsType;

  const currentLabels = typeLabels[type] ?? typeLabels[PaymentsType.INITIAL];
  const statusLabel = currentLabels[+status as PaymentStatus];

  return (
    <Styled.StatusCard>
      {statusLabel ? (
        <Styled.Content $dividerDisabled>{statusLabel}</Styled.Content>
      ) : (
        <WrongParamsError />
      )}
    </Styled.StatusCard>
  );
};

export default PaymentResult;
