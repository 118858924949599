import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Link, useTheme } from "@involve-software/uikit";
import { useNavigation, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

import type { PaymentDetailsLoaderData } from "modules/payment/data/loader-data";
import type { LocaleText } from "types/locales";
import { type PaymentCreateRedirect, createPayment } from "api/payment/create";
import { calculationErrorCodeText } from "modules/payment/data/errors";
import { fiatCurrencyAlias } from "modules/payment/data/currency";
import { useToggleSnackbar } from "utils/hooks/useToggleSnackbar";
import { usePayMethodName } from "utils/hooks/usePayMethodName";
import { formatNumber } from "modules/payment/utils/formatting";
import SelfSubmittingForm from "components/selfSubmittingForm";
import { PaymentAddsOnConfig } from "api/payment/calculation";
import InputsLoadingSkeleton from "./InputsLoadingSkeleton";
import { calculationQuery } from "modules/payment/queries";
import useInfo from "modules/payment/utils/hooks/useInfo";
import { PaymentContext } from "modules/payment/layout";
import LoadingOverlay from "components/LoadingOverlay";
import AddOnsInput from "components/Input/AddOnsInput";
import ManualBlock from "../InfoBlocks/Manual";
import { getPmImgSrc } from "utils/media";
import InfoBlocks from "../InfoBlocks";
import TextSwitch from "../TextSwitch";
import { env } from "utils/env";

import * as Styled from "./index.styled";

interface PaymentDetailsFormProps extends React.HTMLAttributes<HTMLDivElement> {
  payways: PaymentDetailsLoaderData["payways"];
}

// TODO: Decompose inputs/blocks into seperate components
const PaymentDetailsForm: React.FC<PaymentDetailsFormProps> = ({
  payways,
  ...props
}) => {
  const { paymentId } = useParams();
  const [paymentCreateData, setPaymentCreateData] =
    useState<PaymentCreateRedirect | null>(null);

  const session = useContext(PaymentContext);

  const [termsAccepted, setTermsAccepted] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigation = useNavigation();

  const { skeleton } = useTheme();

  const {
    register,
    setValue,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit: handleRhkSubmit,
  } = useFormContext();

  const payway = watch("payway");

  const {
    data: calculationData,
    error: calculationError,
    isLoading: isCalculationLoading,
  } = useQuery(
    calculationQuery({
      paymentId: paymentId as string,
      payway: payway ?? session.payway,
    })
  );

  const payMethodName = usePayMethodName({
    transObj: calculationData?.paymethodNameTranslations,
    defaultValue: calculationData?.paymethodName,
  });

  useEffect(() => {
    if (
      isSubmitting ||
      (isSubmitSuccessful && navigation.state === "loading")
    ) {
      setLoading(true);
      return () => setLoading(false);
    }
  }, [navigation.state, isSubmitting, isSubmitSuccessful]);

  const { t, i18n } = useTranslation("payment");

  const toggleSnackbar = useToggleSnackbar();

  const handleSubmit = handleRhkSubmit(async ({ payway, ...formData }) => {
    try {
      const { data: paymentData } = await createPayment(paymentId as string, {
        payway: payway || session.payway,
        form: formData,
      });

      setPaymentCreateData(paymentData);
    } catch {
      toggleSnackbar({
        message: t("details.errors.creation"),
        type: "error",
      });
    }
  });

  const { icons } = useTheme();

  const { manual } = useInfo(paymentId as string, watch("payway"));

  // TODO: Refactor
  const calculationErrorData = calculationError?.data.data;
  const errorLocaleKey =
    calculationErrorData && calculationErrorCodeText[calculationErrorData.code];
  const calculationErrorText =
    calculationErrorData &&
    (errorLocaleKey
      ? t(`details.errors.${errorLocaleKey}`, {
          minAmount: calculationErrorData?.data.minAmount,
          maxAmount: calculationErrorData?.data.maxAmount,
        })
      : t("details.errors.calculation"));

  return (
    <>
      <div {...props}>
        {loading && <LoadingOverlay />}

        {paymentCreateData && (
          <SelfSubmittingForm
            method={paymentCreateData.method}
            action={paymentCreateData.url}
            data={paymentCreateData.data}
          />
        )}

        <Styled.Title>{t("details.title")}</Styled.Title>

        <form onSubmit={handleSubmit}>
          <Styled.DetailsList>
            <Styled.Item>
              <Styled.ItemLabel>
                {t("details.fields.payMethod.title")}:
              </Styled.ItemLabel>
              <Styled.ItemContent>
                {isCalculationLoading ? (
                  <Styled.LoadingSkeleton
                    baseColor={skeleton.baseColor}
                    highlightColor={skeleton.highlightColor}
                  />
                ) : (
                  <>
                    {calculationData && (
                      <Styled.PayMethodIcon>
                        <img
                          alt=""
                          src={getPmImgSrc(
                            calculationData.paymethodId as number
                          )}
                        />
                      </Styled.PayMethodIcon>
                    )}
                    <Styled.PayMethodName>{payMethodName}</Styled.PayMethodName>
                  </>
                )}
              </Styled.ItemContent>
            </Styled.Item>

            {isCalculationLoading ? (
              <InputsLoadingSkeleton
                baseColor={skeleton.baseColor}
                highlightColor={skeleton.highlightColor}
              />
            ) : (
              calculationData &&
              Object.entries(calculationData?.addOnsConfig).map(
                ([name, config]: [string, PaymentAddsOnConfig]) => (
                  <Styled.Item key={name}>
                    <Styled.StartItemLabel>
                      {config.label[i18n.language as keyof LocaleText]}
                    </Styled.StartItemLabel>
                    <Styled.InputContent>
                      <AddOnsInput
                        name={name}
                        config={config}
                        InputProps={register(name, {
                          required: !config.optional
                            ? t("details.validation:required")
                            : false,
                          pattern: {
                            value: new RegExp(config.regex),
                            message: t("details.validation:format"),
                          },
                        })}
                        errorText={errors[name] && `${errors[name]?.message}`}
                        // TODO: Add fullWidth prop in UIKit
                        AreaProps={{ style: { maxWidth: 464 } }}
                      />
                    </Styled.InputContent>
                  </Styled.Item>
                )
              )
            )}

            <Styled.Item>
              <Styled.PaywayItemLabel>
                {t("details.fields.payway.title")}:
                <Styled.CurrencyHelperText>
                  {t("details.fields.payway.helperText")}
                </Styled.CurrencyHelperText>
              </Styled.PaywayItemLabel>
              <Styled.CurrencyContent>
                <Styled.CurrencySwitches>
                  {payways?.map(({ currency, name }) => (
                    <TextSwitch
                      key={`payway-${name}`}
                      active={payway === name}
                      onClick={() => setValue("payway", name)}
                    >
                      {fiatCurrencyAlias[currency]}
                    </TextSwitch>
                  ))}
                  {!payways && calculationData && (
                    <TextSwitch active>
                      {calculationData.payerCurrency.alias}
                    </TextSwitch>
                  )}
                </Styled.CurrencySwitches>

                {calculationErrorText && (
                  <Styled.MethodsErrorText>
                    <icons.statuses.warning.component /> {calculationErrorText}
                  </Styled.MethodsErrorText>
                )}

                <InfoBlocks />
              </Styled.CurrencyContent>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemLabel>
                {t("details.fields.payerSum.title")}:
              </Styled.ItemLabel>
              <Styled.ItemContent>
                {isCalculationLoading ? (
                  <Styled.LoadingSkeleton
                    baseColor={skeleton.baseColor}
                    highlightColor={skeleton.highlightColor}
                  />
                ) : (
                  <>
                    {calculationData?.payerPrice
                      ? `${formatNumber(
                          (calculationData?.payerPrice as number) -
                            (calculationData?.payerFee as number),
                          2
                        )} ${calculationData?.payerCurrency.alias}`
                      : "-"}
                  </>
                )}
              </Styled.ItemContent>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemLabel>
                {t("details.fields.payerFee.title")}:
                <Styled.ComissionTooltip
                  content={t("details.fields.payerFee.tooltip")}
                  side="bottom"
                >
                  <Styled.InfoIcon>
                    <icons.questionMark.component />
                  </Styled.InfoIcon>
                </Styled.ComissionTooltip>
              </Styled.ItemLabel>
              <Styled.ItemContent>
                {isCalculationLoading ? (
                  <Styled.LoadingSkeleton
                    baseColor={skeleton.baseColor}
                    highlightColor={skeleton.highlightColor}
                  />
                ) : (
                  <>
                    {typeof calculationData?.payerFee === "number"
                      ? `${formatNumber(calculationData?.payerFee, 2)} ${
                          calculationData?.payerCurrency.alias
                        }`
                      : "-"}
                  </>
                )}
              </Styled.ItemContent>
            </Styled.Item>
            <Styled.SumItem>
              <Styled.Divider />
              <Styled.SumItemValue>
                <Styled.StartItemLabel>
                  {t("details.sum.title")}:
                </Styled.StartItemLabel>
                <Styled.SumItemContent>
                  {isCalculationLoading ? (
                    <Styled.LoadingSkeleton
                      baseColor={skeleton.baseColor}
                      highlightColor={skeleton.highlightColor}
                    />
                  ) : (
                    <>
                      {calculationData?.payerPrice
                        ? `${formatNumber(calculationData?.payerPrice, 2)} ${
                            calculationData?.payerCurrency.alias
                          }`
                        : `-`}
                    </>
                  )}
                </Styled.SumItemContent>
              </Styled.SumItemValue>
            </Styled.SumItem>
          </Styled.DetailsList>
          <Styled.PaymentSumbit>
            <Checkbox
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              label={
                <Trans i18nKey="common:tNsConditions">
                  I agree to
                  <Link
                    href={
                      env.REACT_APP_AGREEMENT_LINK + `?lang=${i18n.language}`
                    }
                  >
                    {" "}
                    T&C terms
                  </Link>
                </Trans>
              }
            />

            <Styled.PaymentButton
              type="submit"
              disabled={!termsAccepted}
              fullWidth
            >
              {t("details.actions.submit")}
            </Styled.PaymentButton>
          </Styled.PaymentSumbit>
        </form>
      </div>
      <ManualBlock
        isLoading={manual.isLoading && manual.isFetching}
        data={manual.data}
      />
    </>
  );
};

export default PaymentDetailsForm;
