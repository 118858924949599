import { Link } from "@involve-software/uikit";
import styled from "styled-components";

import { ReactComponent as LockImage } from "assets/images/access-denied/lock.svg";
import { down } from "utils/breakpoints";

export const AccessDenied = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 77px;
`;

export const Lock = styled(LockImage)`
  max-width: 154px;
  max-height: 152px;
`;

export const AccessDeniedTitle = styled.h1`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin: ${({ theme }) => theme.spacing.primitives["s-56"]} 0 0;
  color: ${({ theme }) => theme.colors["text-primary"]};

  ${down("md")} {
    font-size: 18px;
    line-height: 24px;
    margin-top: ${({ theme }) => theme.spacing.primitives["s-48"]};
  }
`;

export const SupportText = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin: ${({ theme }) => theme.spacing.primitives["s-24"]} 0 0;
  color: ${({ theme }) => theme.colors["text-subtle"]};

  ${down("md")} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SupportLink = styled(Link)`
  font-size: 18px;

  ${down("md")} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const RequestDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-08"]};
  margin-top: ${({ theme }) => theme.spacing.primitives["s-20"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
`;

export const RequestDetail = styled.div`
  font-size: 18px;

  ${down("md")} {
    font-size: 16px;
  }
`;

export const RequestValue = styled.span`
  font-weight: bold;
  font-size: 18px;

  ${down("md")} {
    font-size: 16px;
  }
`;
