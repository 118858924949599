import React from "react";
import { Card } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";

import * as Styled from "./index.styled";

interface InvoiceCardProps {
  id: string;
  sum: number;
  currency: string;
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({ id, sum, currency }) => {
  const { t } = useTranslation("binancePay");

  return (
    <Styled.InvoiceCard>
      <Styled.Heading>
        <Card.HeadingTitle>
          <Styled.Title>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {t("common:invoice")} №{id}
          </Styled.Title>
        </Card.HeadingTitle>
      </Styled.Heading>
      <Styled.Content>
        <Styled.DetailsList>
          <Styled.DetailsItem>
            <Styled.DetailsSumLabel>
              {t("binancePay:amount")}
            </Styled.DetailsSumLabel>
            <Styled.DetailsContent>
              <Styled.Sum>
                {sum} {currency}
              </Styled.Sum>
            </Styled.DetailsContent>
          </Styled.DetailsItem>
        </Styled.DetailsList>
      </Styled.Content>
    </Styled.InvoiceCard>
  );
};

export default InvoiceCard;
