import React, { FC, useState } from "react";
import { Button, Input, useTheme } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { IQPaymentsSession, sendQPaymentsSMS } from "api/payment/qPayments";
import { PaymentStatus } from "api/payment/status";
import { IQPaymentsForm } from "./index.interface";
import { useToggleSnackbar } from "utils/hooks/useToggleSnackbar";
import { PaymentsType } from "types/payment";

import * as Styled from "./index.styled";
import * as ParentStyled from "../../index.styled";

const QPaymentsCard: FC<Pick<IQPaymentsSession, "phone">> = ({ phone }) => {
  const { t } = useTranslation("qPayments");

  const { paymentId } = useParams();
  const navigate = useNavigate();
  const toggleSnackbar = useToggleSnackbar();
  const { images } = useTheme();

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IQPaymentsForm>();

  const handleDialogClose = () => setIsOpenDialog(false);
  const handleDialogOpen = () => setIsOpenDialog(true);

  const onUpdatePayment = async (values: IQPaymentsForm) => {
    try {
      const { data } = await sendQPaymentsSMS(paymentId as string, values.otp);

      if (data.status === PaymentStatus.SUCCESS) {
        data.successUrl
          ? (window.location.href = data.successUrl)
          : navigate({
              pathname: `/result-page/${paymentId}`,
              search: `?status=${PaymentStatus.SUCCESS}`,
            });
      } else if (data.status === PaymentStatus.REJECTED) {
        data.failedUrl
          ? (window.location.href = data.failedUrl)
          : navigate({
              pathname: `/result-page/${paymentId}`,
              search: `?status=${PaymentStatus.REJECTED}`,
            });
      } else {
        navigate({
          pathname: `/result-page/${paymentId}`,
          search: `?status=${PaymentStatus.IN_PROGRESS}&type=${PaymentsType.QPAYMENTS}`,
        });
      }
    } catch (e) {
      toggleSnackbar({
        type: "error",
        message: t("common:error"),
      });
    }
  };

  return (
    <Styled.QPaymentsCard>
      <Styled.QPaymentsForm onSubmit={handleSubmit(onUpdatePayment)}>
        <Styled.PhoneImage>
          <images.phone.component />
        </Styled.PhoneImage>
        <Styled.Paragraph>
          {t("qPayments:sendCode")} {phone}. <br />
          {t("qPayments:inputCode")}
        </Styled.Paragraph>
        <Input
          fullWidth
          label={t("qPayments:smsLabel")}
          errorText={errors.otp?.message}
          InputProps={register("otp", {
            required: t("validation:required"),
          })}
        />
        <ParentStyled.ButtonsGroup>
          <Button
            variant={"outlined"}
            color={"error"}
            type={"button"}
            fullWidth
            onClick={handleDialogOpen}
          >
            {t("qPayments:cancelButton")}
          </Button>
          <Button type={"submit"} fullWidth disabled={isSubmitting}>
            {t("qPayments:confirm")}
          </Button>
        </ParentStyled.ButtonsGroup>

        <Styled.CancelPaymentDialog
          title={t("qPayments:cancelModal.title")}
          open={isOpenDialog}
          onClose={handleDialogClose}
          width={"md"}
        >
          <Styled.CancelPaymentTitle>
            {t("qPayments:cancelModal.confirmQuestion")}
          </Styled.CancelPaymentTitle>

          <ParentStyled.ButtonsGroup>
            <Button
              type={"button"}
              variant={"outlined"}
              fullWidth
              onClick={handleDialogClose}
            >
              {t("qPayments:cancelModal.prev")}
            </Button>
            <Button
              type={"submit"}
              color={"error"}
              fullWidth
              disabled={isSubmitting}
              onClick={() => onUpdatePayment({ otp: "0" })}
            >
              {t("qPayments:cancelModal.confirm")}
            </Button>
          </ParentStyled.ButtonsGroup>
        </Styled.CancelPaymentDialog>
      </Styled.QPaymentsForm>
    </Styled.QPaymentsCard>
  );
};

export default QPaymentsCard;
