import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import WrongParamsError from "components/Errors/WrongParams";
import PlatipayPayment from "./pages/PlatipayPayment";

import { platipayLoader } from "./loaders";

export default (
  <Route
    path="p2p_pp/:paymentId"
    loader={platipayLoader}
    element={<PlatipayPayment />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  />
);
