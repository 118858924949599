import React from "react";
import { Button, Card, Input, useTheme } from "@involve-software/uikit";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "react-router-dom";

import type { IBanksCactusPaymentSession } from "api/payment/banks";
import { useToggleSnackbar } from "utils/hooks/useToggleSnackbar";

import * as Styled from "./index.styled";

const CactusBanksPayment = () => {
  const { t } = useTranslation("cactusBanks");

  const { card, cardHolder, successUrl } = useRouteLoaderData(
    "cactusBanks"
  ) as IBanksCactusPaymentSession;

  const toggleSnackbar = useToggleSnackbar();

  const copyDetails = (field: string) => {
    navigator.clipboard.writeText(field);

    toggleSnackbar({
      type: "success",
      message: t("common:copied"),
    });
  };

  const { icons } = useTheme();

  return (
    <Styled.BanksPaymentCard>
      <Card.Content $dividerDisabled>
        <Styled.DetailsContent>
          <Styled.Title>{t("payment.detailsTitle")}</Styled.Title>
          <Styled.Subtitle>{t("payment.detailsSubtitle")}</Styled.Subtitle>
          <Styled.DetailsFields>
            {card && (
              <Input
                label={t("payment.fields.cardNumber")}
                startAdornment={<icons.card.component />}
                endAdornment={
                  <Styled.CopyButton onClick={() => copyDetails(card)}>
                    <icons.actions.copy.component />
                  </Styled.CopyButton>
                }
                value={card}
                InputProps={{ readOnly: true }}
                fullWidth
                readonly
              />
            )}
            {cardHolder && (
              <Input
                label={t("payment.fields.cardHolder")}
                startAdornment={<icons.profile.component />}
                endAdornment={
                  <Styled.CopyButton onClick={() => copyDetails(cardHolder)}>
                    <icons.actions.copy.component />
                  </Styled.CopyButton>
                }
                value={cardHolder}
                InputProps={{ readOnly: true }}
                fullWidth
                readonly
              />
            )}
          </Styled.DetailsFields>
          {successUrl && (
            <Styled.ReturnLink to={successUrl} target="_blank">
              <Button variant="outlined">
                {t("cactusBanks:returnButton")}
              </Button>
            </Styled.ReturnLink>
          )}
        </Styled.DetailsContent>
      </Card.Content>
    </Styled.BanksPaymentCard>
  );
};

export default CactusBanksPayment;
