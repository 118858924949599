import { Overlay } from "@involve-software/uikit";
import styled from "styled-components";

import { rotate } from "styles/animations";

export const LoadingOverlay = styled(Overlay)`
  // TODO: Should be in UIKit component styles
  z-index: 99;
  background: ${({ theme }) => theme.colors["background-overlay"]};

  svg {
    width: 85px;
    height: 85px;

    animation: ${rotate} 1s infinite;
  }
`;
