import { factory, getAuthorization } from "api/base";
import { RequestMethod } from "utils/constants";
import { PaymentStatus } from "api/payment/status";

export interface IQPaymentsSession {
  amount: number;
  currency: {
    alias: string;
    code: number;
  };
  phone: string;
  description: string | null;
  paymentId: string;
  failedUrl: string | null;
  successUrl: string | null;
  lng: string;
  otpSent: boolean;
  status: PaymentStatus;
}

export interface IQPaymentsSMS {
  failedUrl: string | null;
  successUrl: string | null;
  status: PaymentStatus;
}

export const getQPaymentsSession = factory(
  (r) => (session: string) =>
    r<IQPaymentsSession>(RequestMethod.get, `/qpayments/session`, undefined, {
      headers: getAuthorization(session),
    })
);

export const sendQPaymentsSMS = factory(
  (r) => (session: string, otp: string) =>
    r<IQPaymentsSMS>(
      RequestMethod.post,
      `/qpayments/session`,
      { otp },
      {
        headers: getAuthorization(session),
      }
    )
);
