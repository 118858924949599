import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, useTheme } from "@involve-software/uikit";

import useLanguageChange from "utils/hooks/useLanguageChange";

import { AVAILABLE_LNGS, LANGUAGES, type Language } from "data/i18n";

import * as Styled from "./index.styled";

export const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const { icons } = useTheme();

  const [lngMenuOpen, setLngMenuOpen] = useState(false);

  const changeLanguage = useLanguageChange();

  const selectLanguage = (lng: Language) => {
    changeLanguage(LANGUAGES[lng].code);
    setLngMenuOpen(false);
  };

  return (
    <Styled.LanguageSelect>
      <Dropdown
        open={lngMenuOpen}
        onOpenChange={setLngMenuOpen}
        size="small"
        side="bottom"
        align="center"
        trigger={
          <Styled.LanguageDropdownHeader $isOpen={lngMenuOpen}>
            <Styled.Option>
              <icons.language.component />
              {LANGUAGES[i18n.language as Language].name}
            </Styled.Option>
            <icons.actions.arrowDown.component />
          </Styled.LanguageDropdownHeader>
        }
      >
        {AVAILABLE_LNGS.map((lng) => (
          <Dropdown.Item
            key={lng}
            onClick={() => selectLanguage(lng)}
            $active={i18n.language === LANGUAGES[lng].code}
          >
            <Styled.Option>{LANGUAGES[lng].name}</Styled.Option>
          </Dropdown.Item>
        ))}
      </Dropdown>
    </Styled.LanguageSelect>
  );
};
