import { useContext } from "react";

import {
  type ToggleSnackbar,
  SnackbarContext,
} from "components/Providers/Snackbar";

export const useToggleSnackbar = () => {
  const { toggleSnackbar } = useContext(SnackbarContext);
  return toggleSnackbar as ToggleSnackbar;
};
