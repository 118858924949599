import React from "react";
import { useTranslation } from "react-i18next";

import type { PaymentInfo } from "api/payment/info";
import type { LocaleText } from "types/locales";

import * as Styled from "../index.styled";

type ManualBlockProps = {
  isLoading: boolean;
  data?: PaymentInfo;
};

const ManualBlock: React.FC<ManualBlockProps> = ({ isLoading, data }) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      {isLoading && <Styled.BottomLoadingSkeleton />}
      {data && (
        <Styled.ManualAccordion label={t("payment:details.accordion.label")}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.text[i18n.language as keyof LocaleText],
            }}
          />
        </Styled.ManualAccordion>
      )}
    </>
  );
};

export default ManualBlock;
