import React from "react";
import { Button, useTheme } from "@involve-software/uikit";
import { Trans, useTranslation } from "react-i18next";

import { NcpayCardProps } from "modules/ncpay/data/types";
import CopyBlock from "components/CopyBlock";

import * as Styled from "./index.styled";
import { ExpiredAlert } from "./index.styled";

const NcpayCard: React.FC<NcpayCardProps> = ({
  card,
  returnUrl,
  successUrl,
}) => {
  const { t } = useTranslation("ncpay");
  const { icons } = useTheme();

  return (
    <Styled.NcpayCard>
      <Styled.NcpayTitle>{t("ncpay:payment")}</Styled.NcpayTitle>
      <Styled.NcpaySubtitle>{t("ncpay:paymentGuide")}</Styled.NcpaySubtitle>
      <Styled.NcpayDetails>
        <CopyBlock
          copyText={card}
          label={t("ncpay:details")}
          startAdornment={<icons.card.component />}
        />

        <ExpiredAlert title={t("common:alerts.important")} variant="warning">
          <Trans i18nKey="ncpay:expiredText">
            Payment details are only valid for <b>15 minutes!</b>
          </Trans>
        </ExpiredAlert>

        <Styled.ButtonsGroup>
          {returnUrl && (
            <Styled.ButtonLinkWrap href={returnUrl}>
              <Button variant="outlined">{t("ncpay:returnButton")}</Button>
            </Styled.ButtonLinkWrap>
          )}

          {successUrl && (
            <Styled.ButtonLinkWrap href={successUrl}>
              <Button>{t("common:alreadyPaid")}</Button>
            </Styled.ButtonLinkWrap>
          )}
        </Styled.ButtonsGroup>
      </Styled.NcpayDetails>
    </Styled.NcpayCard>
  );
};

export default NcpayCard;
