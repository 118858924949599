import { useCallback, useEffect, useRef, useState } from "react";

import { useInterval } from "./useInterval";

export function useCountdown(
  seconds: number,
  initSeconds?: number
): [number, () => void] {
  const intSeconds = Math.floor(seconds);
  const intInitSeconds = initSeconds ? Math.floor(initSeconds) : undefined;
  const [timeLeft, setTimeLeft] = useState(
    intInitSeconds != null ? intInitSeconds : intSeconds
  );

  const deadlineSeconds = useRef<number>();

  const updateDeadlineSeconds = useCallback(() => {
    deadlineSeconds.current =
      Math.floor(new Date().getTime() / 1000) + (intInitSeconds ?? intSeconds);
  }, [intInitSeconds, intSeconds]);

  useEffect(() => {
    updateDeadlineSeconds();
    // eslint-disable-next-line
  }, []);

  const resetCountdown = useCallback(() => {
    setTimeLeft(intInitSeconds != null ? intInitSeconds : intSeconds);

    updateDeadlineSeconds();
  }, [intSeconds, intInitSeconds, updateDeadlineSeconds]);

  useInterval(() => {
    if (timeLeft <= 0 || !deadlineSeconds.current) return;

    setTimeLeft(
      deadlineSeconds.current - Math.floor(new Date().getTime() / 1000)
    );
  }, 1000);

  return [timeLeft, resetCountdown];
}
