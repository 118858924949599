import React, { FC } from "react";
import { useTheme } from "@involve-software/uikit";

import { timeConvert, formatTime } from "utils/time";

import * as Styled from "./index.styled";

interface PaymentExpiryTimerProps {
  secondsLeft: number;
  countdown: number;
}

const PaymentExpiryTimer: FC<PaymentExpiryTimerProps> = ({
  secondsLeft,
  countdown,
}) => {
  const { colors } = useTheme();

  const [hours, minutes, seconds] = formatTime(timeConvert(countdown));

  const timeString = `${hours}:${minutes}:${seconds}`;

  const circleConfig = {
    strokeDasharray: 101,
    strokeDashoffset: 100 - ((secondsLeft - countdown) / secondsLeft) * 100,
  };

  return (
    <Styled.PaymentExpiryTimer>
      {secondsLeft > 0 ? (
        <>
          {timeString}
          <svg width="18" height="18" viewBox="0 0 18 18">
            <circle
              cx="9"
              cy="9"
              r="7"
              fill="none"
              stroke={colors["icon-brand"]}
              strokeWidth="3"
            />
            <circle
              cx="9"
              cy="9"
              r="7"
              fill="none"
              stroke={colors["layer-03"]}
              strokeDasharray={circleConfig.strokeDasharray}
              strokeDashoffset={circleConfig.strokeDashoffset}
              strokeWidth="4"
              pathLength="100"
            />
          </svg>
        </>
      ) : (
        "00:00:00"
      )}
    </Styled.PaymentExpiryTimer>
  );
};

export default PaymentExpiryTimer;
