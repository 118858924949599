import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  redirect,
} from "react-router-dom";

import { AVAILABLE_LNGS, FALLBACK_LNG, type Language } from "data/i18n";
import AccessDeniedPage from "modules/error/pages/AccessDenied";
import ErrorBoundary from "components/Errors/ErrorBoundary";
import NotFoundPage from "modules/error/pages/NotFound";
import { i18nInstance } from "utils/i18n";
import Layout from "components/Layout";

import QrPaymentRouter from "modules/qr-pay";
import CactusPaymentRouter from "modules/cactus-pay";
import CactusBanksPaymentRouter from "modules/cactus-pay-banks";
import NcpayRouter from "modules/ncpay";
import PlatipayRouter from "modules/platipay";
import CardTransferRouter from "modules/card-transfer";
import BinancePayRouter from "modules/binancepay";
import QPaymentsRouter from "modules/qpayments";
import PaymentRouter from "modules/payment";
import PaycosRouter from "modules/paycos";
import EmanatRouter from "modules/emanat";
import CryptoRouter from "modules/crypto";
import ResultRouter from "modules/result";
import ErrorRouter from "modules/error";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <Layout>
          <Outlet />
        </Layout>
      }
      loader={({ params, request }) => {
        if (!AVAILABLE_LNGS.includes(params.lang as Language)) {
          const { pathname: urlPathname, search } = new URL(request.url);

          const pathname = urlPathname === "/" ? "" : urlPathname;

          return redirect(
            `/${i18nInstance.language ?? FALLBACK_LNG}${pathname}${search}`
          );
        }

        i18nInstance.changeLanguage(params.lang);
        return null;
      }}
    >
      <Route path={`/:lang/*`} errorElement={<ErrorBoundary />}>
        {ErrorRouter}
        {PaymentRouter}
        {QrPaymentRouter}
        {CactusPaymentRouter}
        {CactusBanksPaymentRouter}
        {NcpayRouter}
        {CardTransferRouter}
        {BinancePayRouter}
        {PaycosRouter}
        {EmanatRouter}
        {PlatipayRouter}
        {QPaymentsRouter}
        {ResultRouter}
        <Route path="crypto">{CryptoRouter}</Route>
        <Route path="access_denied" element={<AccessDeniedPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Route>
  )
);

export default router;
