import React from "react";
import { Outlet, Route } from "react-router-dom";

import {
  cryptoDetailsLoader,
  cryptoLoader,
  paymentDetailsLoader,
  redirectLoader,
} from "./loaders";
import CryptoPaymentLayout from "modules/crypto/layout";

import CryptoDetails from "./pages/Details";
import CryptoPayment from "./pages/Payment";

export default (
  <Route
    path="payment/:paymentId"
    element={
      <CryptoPaymentLayout>
        <Outlet />
      </CryptoPaymentLayout>
    }
    loader={cryptoLoader}
  >
    <Route path="" loader={redirectLoader} />
    <Route
      path="details"
      element={<CryptoDetails />}
      loader={cryptoDetailsLoader}
    />
    <Route
      path="payment"
      element={<CryptoPayment />}
      loader={paymentDetailsLoader}
    />
  </Route>
);
