import React from "react";
import { Card } from "@involve-software/uikit";
import { Route } from "react-router-dom";

import WrongParamsError from "components/Errors/WrongParams";
import CactusPayment from "./pages/CactusPayment";

import { cactusPaymentLoader } from "./loaders";

export default (
  <Route
    path="cactus/:paymentId"
    loader={cactusPaymentLoader}
    element={<CactusPayment />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: "100%" }}>
        <WrongParamsError />
      </Card>
    }
  />
);
