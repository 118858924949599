import React from "react";
import { Button, useTheme } from "@involve-software/uikit";
import { Trans, useTranslation } from "react-i18next";

import type { PlatipayCardProps } from "./index.interface";
import CopyBlock from "components/CopyBlock";

import * as Styled from "./index.styled";
import { ExpiredAlert } from "./index.styled";

const PlatipayCard: React.FC<PlatipayCardProps> = ({
  card,
  returnUrl,
  successUrl,
}) => {
  const { t } = useTranslation("platipay");
  const { icons } = useTheme();

  return (
    <Styled.PlatipayCard>
      <Styled.PlatipayTitle>{t("platipay:payment")}</Styled.PlatipayTitle>
      <Styled.PlatipaySubtitle>
        {t("platipay:paymentGuide")}
      </Styled.PlatipaySubtitle>
      <Styled.PlatipayDetails>
        <CopyBlock
          copyText={card}
          label={t("platipay:details")}
          startAdornment={<icons.card.component />}
        />

        <ExpiredAlert title={t("common:alerts.important")} variant="warning">
          <Trans i18nKey="platipay:expiredText">
            Payment details are only valid for <b>10 minutes!</b>
          </Trans>
        </ExpiredAlert>

        <Styled.ButtonsGroup>
          {returnUrl && (
            <Styled.ButtonLinkWrap href={returnUrl}>
              <Button variant="outlined">{t("platipay:returnButton")}</Button>
            </Styled.ButtonLinkWrap>
          )}

          {successUrl && (
            <Styled.ButtonLinkWrap href={successUrl}>
              <Button>{t("common:alreadyPaid")}</Button>
            </Styled.ButtonLinkWrap>
          )}
        </Styled.ButtonsGroup>
      </Styled.PlatipayDetails>
    </Styled.PlatipayCard>
  );
};

export default PlatipayCard;
