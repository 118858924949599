import React from "react";
import { useLoaderData } from "react-router-dom";

import type { CryptoPaymentSession } from "api/crypto/payment";

export const CryptoPaymentContext = React.createContext<CryptoPaymentSession>(
  null as unknown as CryptoPaymentSession
);

const CryptoPaymentSessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const paymentSession = useLoaderData() as CryptoPaymentSession;

  return (
    <CryptoPaymentContext.Provider value={paymentSession}>
      {children}
    </CryptoPaymentContext.Provider>
  );
};

export default CryptoPaymentSessionProvider;
