import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "@involve-software/uikit";

import ErrorStatusLabel from "modules/result/components/StatusLabels/Error";
import * as Styled from "../index.styled";

const CactusBanksErrorStatus = () => {
  const [searchParams] = useSearchParams();

  const isCredentialsFetchError = searchParams.get("isCredentialsFetchError");
  const failedUrl = searchParams.get("failedUrl");

  const { t } = useTranslation("result");
  const { t: cactusBanksT } = useTranslation("cactusBanks");

  return (
    <>
      <ErrorStatusLabel label={t("error.label")} />
      {isCredentialsFetchError && (
        <Styled.StatusDescription>
          {cactusBanksT("errors.paymentCredentials")}
        </Styled.StatusDescription>
      )}

      {failedUrl && (
        <Link href={failedUrl}>
          <Styled.ActionButton variant="outlined" fullWidth>
            {t("error.action")}
          </Styled.ActionButton>
        </Link>
      )}
    </>
  );
};

export default CactusBanksErrorStatus;
