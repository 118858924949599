import { Link } from "@involve-software/uikit";
import styled from "styled-components";

export const MethodsLink = styled(Link)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
  color: ${({ theme }) => theme.colors["text-brand"]};
`;

export const NavIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;
