import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Button } from "@involve-software/uikit";

import HistoryStatusLabel from "modules/result/components/StatusLabels/History";
import { useToggleSnackbar } from "utils/hooks/useToggleSnackbar";
import { cardTransferStatusQuery } from "modules/result/queries";
import { PaymentStatus } from "api/payment/status";
import { LinkButton } from "../index.styled";

import * as Styled from "./index.styled";

const CardTransferInProgressStatus = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { paymentId } = useParams();

  const { t } = useTranslation("result");
  const { t: tCardTransfer } = useTranslation("cardTransfer");

  const toggleSnackbar = useToggleSnackbar();

  const { data: paymentData } = useQuery(
    cardTransferStatusQuery(
      { paymentId: paymentId as string },
      {
        onError: () =>
          toggleSnackbar({
            message: t("inProgress.errors.fetchFailed"),
            type: "error",
          }),
      }
    )
  );

  const newStatus = paymentData?.status as PaymentStatus;

  useEffect(() => {
    if (newStatus && newStatus !== PaymentStatus.IN_PROGRESS) {
      setSearchParams({ ...searchParams, ...paymentData });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStatus]);

  return (
    <>
      <HistoryStatusLabel label={t("inProgress.label")} />
      <Styled.StatusDescription>
        {tCardTransfer("cardTransfer:inProgress", {
          invoiceId: paymentData?.paymentId,
        })}
      </Styled.StatusDescription>
      {paymentData?.returnUrl && (
        <LinkButton href={paymentData.returnUrl}>
          <Button variant="outlined">{t("common:returnButton")}</Button>
        </LinkButton>
      )}
    </>
  );
};

export default CardTransferInProgressStatus;
