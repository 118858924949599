import { Button, List, Tooltip } from "@involve-software/uikit";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";
import { down } from "utils/breakpoints";

export const Title = styled.span`
  font: ${({ theme }) => theme.typography["label-medium"]};
  color: ${({ theme }) => theme.colors["text-primary"]};
`;

export const DetailsList = styled(List)`
  font: ${({ theme }) => theme.typography["label-medium"]};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.primitives["s-20"]};

  & > ul {
    gap: ${({ theme }) => theme.spacing.primitives["s-24"]};
  }
`;

export const Item = styled(List.Item)`
  justify-content: flex-start;

  ${down("sm")} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.primitives["s-08"]};
    align-items: flex-start;
  }
`;

export const ItemLabel = styled(List.Label)`
  display: inline-flex;
  max-width: 181px;
  font: ${({ theme }) => theme.typography["label-medium"]};
  color: ${({ theme }) => theme.colors["text-subtle"]};

  ${down("sm")} {
    max-width: 100%;
  }
`;

export const PaywayItemLabel = styled(ItemLabel)`
  flex-direction: column;
  align-self: flex-start;
`;

export const StartItemLabel = styled(ItemLabel)`
  align-self: flex-start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors["border-subtle-01"]};
`;

export const SumItem = styled(Item)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-12"]};
`;

export const SumItemValue = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.primitives["s-28"]};

  ${down("sm")} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.primitives["s-08"]};
  }
`;

export const ItemContent = styled(List.Content)`
  max-width: 446px;
  font-size: 16px;
`;

export const InputContent = styled(ItemContent)`
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const CurrencyContent = styled(ItemContent)`
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.primitives["s-10"]};
  align-self: flex-start;
  width: 100%;
`;

export const SumItemContent = styled(ItemContent)`
  font: ${({ theme }) => theme.typography["heading-large"]};
`;

export const PayMethodName = styled.span`
  font-size: 16px;
`;

export const PayMethodIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.primitives["s-12"]};

  img {
    width: 32px;
    height: 32px;
  }
`;

export const CurrencySwitches = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.primitives["s-08"]};

  ${down("sm")} {
    gap: ${({ theme }) => theme.spacing.primitives["s-4"]};
  }
`;

export const CurrencyHelperText = styled.div`
  font: ${({ theme }) => theme.typography["paragraph-x-small"]};
  color: ${({ theme }) => theme.colors["text-subtle"]};
`;

export const PaymentSumbit = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-16"]};
  margin: ${({ theme }) => theme.spacing.primitives["s-32"]} auto auto;
  line-height: 24px;
  max-width: 290px;

  label > span,
  a {
    font-size: 16px;
  }

  ${down("sm")} {
    margin-top: ${({ theme }) => theme.spacing.primitives["s-20"]};
  }
`;

export const PaymentButton = styled(Button)`
  ${down("sm")} {
    max-width: 100%;
  }
`;

export const InfoIcon = styled.span`
  margin-left: ${({ theme }) => theme.spacing.primitives["s-08"]};
  display: flex;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const LoadingSkeleton = styled(Skeleton)`
  width: 120px;
  height: 26px;
`;

export const ComissionTooltip = styled(Tooltip)`
  // TODO: Update defaults in UIKit
  max-width: 155px;
  text-align: center;
  font-size: 12px;
`;

export const MethodsErrorText = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives["s-04"]};
  color: ${({ theme }) => theme.colors["text-danger"]};
  font-size: 12px;
`;
