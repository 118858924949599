import React, { useState, useEffect, useRef } from "react";

import LoadingOverlay from "./LoadingOverlay";

export interface ISelfSubmittingFormProps
  extends React.FormHTMLAttributes<HTMLFormElement> {
  method: string;
  action: string;
  data: { [key: string]: string };
}

export const SelfSubmittingForm: React.FC<ISelfSubmittingFormProps> = ({
  method,
  action,
  data,
  ...formProps
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    formRef.current?.submit();

    return () => setLoading(false);
  }, []);

  return (
    <>
      <form method={method} action={action} ref={formRef} {...formProps}>
        {loading && <LoadingOverlay />}
        {data &&
          // TODO: add polyfill
          Object.entries(data).map(([name, value], index) => (
            <input key={index} type="hidden" name={name} value={value} />
          ))}
      </form>
    </>
  );
};

export default SelfSubmittingForm;
