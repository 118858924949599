import { factory, getAuthorization } from "api/base";
import { RequestMethod } from "utils/constants";
import { snakify } from "utils/object";
import { getResponseCamelization } from "utils/api";

export interface PaymentCreateRedirect {
  id: string;
  method: string;
  url: string;
  data: Record<string, string>;
}

export const createPayment = factory(
  (r) =>
    (
      paymentId: string,
      params: { payway?: string; form?: Record<string, string> }
    ) => {
      const passedParams = {
        ...(params.payway && { payway: params.payway }),
        ...(params.form && { form: params.form }),
      };

      return r<PaymentCreateRedirect>(
        RequestMethod.post,
        `/payment/create`,
        snakify(passedParams),
        {
          headers: getAuthorization(paymentId),
          transformResponse: getResponseCamelization([["data", "data"]]),
        }
      );
    }
);
