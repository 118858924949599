import React, { useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Button, Card, useTheme } from "@involve-software/uikit";

import {
  getCardTransferSession,
  ICardTransferSession,
} from "api/payment/cardTransfer";
import { PaymentStatus } from "api/payment/status";

import { PaymentsType } from "types/payment";

import { useToggleSnackbar } from "utils/hooks/useToggleSnackbar";
import { useCountdown } from "utils/hooks/useCountdown";
import { formatCreditCardNumber16 } from "utils/formatting";

import PaymentExpiryTimer from "./components/PaymentExpiryTimer";
import ExpiryStatusBlock from "./components/ExpiryStatusBlock";

import * as Styled from "./index.styled";

import { ReactComponent as CaretDown } from "assets/icons/caret.svg";

const CardTransferPayment = () => {
  const {
    amount,
    card,
    cardHolder,
    currency,
    returnUrl,
    paymentId,
    expiresIn,
    status,
  } = useLoaderData() as ICardTransferSession;

  const { t } = useTranslation("cardTransfer");

  const { paymentId: token } = useParams();

  const navigate = useNavigate();

  const toggleSnackbar = useToggleSnackbar();

  const [isVisibleFAQInfo, setIsVisibleFAQInfo] = useState(false);
  const [confirmPaymentDialogOpen, setConfirmPaymentDialogOpen] =
    useState(false);

  const { icons } = useTheme();

  const [countdown] = useCountdown(expiresIn ?? 0);

  const toggleVisibleFAQInfo = () => setIsVisibleFAQInfo((prev) => !prev);

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);

    toggleSnackbar({
      type: "success",
      message: t("common:copied"),
    });
  };

  const checkPaymentStatus = async () => {
    try {
      const { data } = await getCardTransferSession(token!);

      if (data.status === PaymentStatus.SUCCESS && data.successUrl) {
        window.location.href = data.successUrl;
        return;
      }

      if (data.status === PaymentStatus.REJECTED && data.failedUrl) {
        window.location.href = data.failedUrl;
        return;
      }

      let searchString = `?status=${data.status}&type=${PaymentsType.CARD_TRANSFER}`;

      if (data.returnUrl) {
        searchString += `&returnUrl=${data.returnUrl}`;
      }

      navigate({
        pathname: `/result-page/${token}`,
        search: searchString,
      });
    } catch (e) {
      toggleSnackbar({
        type: "error",
        message: t("common:error"),
      });
    }
  };

  if (expiresIn && countdown <= 0 && status === PaymentStatus.IN_PROGRESS) {
    return <ExpiryStatusBlock />;
  }

  return (
    <Styled.CardTransferPayment>
      <Styled.BankCardBlock>
        <Card.Heading>
          <Styled.BankCardHeadingTitle>
            <icons.card.component />
            <Card.Title>{t("cardTransfer:receiver")}</Card.Title>
          </Styled.BankCardHeadingTitle>
          {expiresIn && (
            <PaymentExpiryTimer secondsLeft={expiresIn} countdown={countdown} />
          )}
        </Card.Heading>
        <Card.Content $dividerDisabled>
          {cardHolder && (
            <Styled.BankCardItem>
              <Styled.BankCardItemLabel>
                {t("cardTransfer:cardOwner")}
              </Styled.BankCardItemLabel>
              <Styled.BankCardItemValue>
                {cardHolder}
                <icons.actions.copy.component
                  onClick={() => copyText(cardHolder)}
                />
              </Styled.BankCardItemValue>
            </Styled.BankCardItem>
          )}
          <Styled.BankCardItem>
            <Styled.BankCardItemLabel>
              {t("cardTransfer:cardNumber")}
            </Styled.BankCardItemLabel>
            <Styled.BankCardItemValue>
              {formatCreditCardNumber16(card)}
              <icons.actions.copy.component onClick={() => copyText(card)} />
            </Styled.BankCardItemValue>
          </Styled.BankCardItem>
        </Card.Content>
      </Styled.BankCardBlock>
      <Styled.InvoiceBlock>
        <Styled.Subtitle>
          <icons.invoice.component />
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {t("common:invoice")} № {paymentId}
        </Styled.Subtitle>
        <Styled.InvoiceTotal>
          <label>{t("cardTransfer:totalSum")}</label>
          <p>
            {amount} <span>{currency.alias}</span>
          </p>
        </Styled.InvoiceTotal>
      </Styled.InvoiceBlock>
      <Styled.WarningAlert
        variant="warning"
        title={t("cardTransfer:important")}
      >
        <Trans i18nKey={"cardTransfer:guide.step1"}>
          Send <b>the exact amount</b>, otherwise your payment may be lost.
        </Trans>
      </Styled.WarningAlert>
      <Styled.AdditionalInfo>
        <Styled.AdditionalInfoTrigger
          onClick={toggleVisibleFAQInfo}
          active={isVisibleFAQInfo}
        >
          <Styled.Subtitle>
            <icons.questionMark.component />
            {t("cardTransfer:howToPay")}
          </Styled.Subtitle>
          <CaretDown />
        </Styled.AdditionalInfoTrigger>

        {isVisibleFAQInfo && (
          <Styled.AdditionalInfoList>
            <Styled.AdditionalInfoItem>
              <span>1.</span>
              {t("cardTransfer:howToPayGuide.step1")}
            </Styled.AdditionalInfoItem>
            <Styled.AdditionalInfoItem>
              <span>2.</span>
              {t("cardTransfer:howToPayGuide.step2")}
            </Styled.AdditionalInfoItem>
            <Styled.AdditionalInfoItem>
              <span>3.</span>
              {t("cardTransfer:howToPayGuide.step3")}
            </Styled.AdditionalInfoItem>
            <Styled.AdditionalInfoItem>
              <span>4.</span>
              {t("cardTransfer:howToPayGuide.step4")}
            </Styled.AdditionalInfoItem>
            <Styled.AdditionalInfoItem>
              <span>5.</span>
              {t("cardTransfer:howToPayGuide.step5")}
            </Styled.AdditionalInfoItem>
          </Styled.AdditionalInfoList>
        )}
      </Styled.AdditionalInfo>

      <Styled.ButtonGroup>
        <Button fullWidth onClick={() => setConfirmPaymentDialogOpen(true)}>
          {t("cardTransfer:payButton")}
        </Button>
        {returnUrl && (
          <a href={returnUrl}>
            <Button fullWidth variant="outlined">
              {t("cardTransfer:returnButton")}
            </Button>
          </a>
        )}
      </Styled.ButtonGroup>
      <Styled.ConfirmPaymentDialog
        title={t("cardTransfer:confirmDialog.title", {
          invoiceId: paymentId,
        })}
        open={confirmPaymentDialogOpen}
        onClose={() => setConfirmPaymentDialogOpen(false)}
      >
        <p>
          {t("cardTransfer:confirmDialog.transferIsDone")}{" "}
          <span>{formatCreditCardNumber16(card)}</span>.
        </p>
        <p>{t("cardTransfer:confirmDialog.pressTheButton")}</p>
        <Styled.ConfirmPaymentButton fullWidth onClick={checkPaymentStatus}>
          {t("cardTransfer:confirmDialog.confirm")}
        </Styled.ConfirmPaymentButton>
      </Styled.ConfirmPaymentDialog>
    </Styled.CardTransferPayment>
  );
};

export default CardTransferPayment;
