import React, { useState } from "react";
import { useTheme } from "@involve-software/uikit";

import QrPaymentDialog from "../../QrPaymentDialog";

import * as Styled from "./index.styled";

interface QrActionButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  address: string;
}

const QrActionButton: React.FC<QrActionButtonProps> = ({
  address,
  ...props
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { icons } = useTheme();

  return (
    <>
      <Styled.IconButton onClick={() => setDialogOpen(true)} {...props}>
        <icons.actions.qr.component />
      </Styled.IconButton>
      <QrPaymentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        address={address}
      />
    </>
  );
};

export default QrActionButton;
