import { type LoaderFunction } from "react-router-dom";

import { getNcpaySession } from "api/payment/ncpay";

export const ncpayLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error("Required params are missing");
  }

  const { data } = await getNcpaySession(params.paymentId);

  return data;
};
