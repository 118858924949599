import styled, { css } from "styled-components";
import { down } from "utils/breakpoints";

export const TextSwitch = styled.button<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.primitives["s-08"]}
    ${({ theme }) => theme.spacing.primitives["s-20"]};
  background: transparent;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radius["radius-pr-subtle"]};
  font: ${({ theme }) => theme.typography["label-large"]};
  color: ${({ theme }) => theme.colors["text-subtle"]};
  outline: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors["text-primary"]};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors["text-primary"]};
      border-color: ${({ theme }) => theme.colors["border-button"]};
      text-decoration: underline 0.5px;
    `}

  ${down("sm")} {
    padding: ${({ theme }) => theme.spacing.primitives["s-12"]}
      ${({ theme }) => theme.spacing.primitives["s-16"]};
  }
`;
