import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@involve-software/uikit";

import { useToggleSnackbar } from "utils/hooks/useToggleSnackbar";

import * as Styled from "./index.styled";

interface CopyActionButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  copyText: string;
}

const CopyActionButton: React.FC<CopyActionButtonProps> = ({
  copyText,
  ...props
}) => {
  const toggleSnackbar = useToggleSnackbar();

  const { t } = useTranslation();

  const { icons } = useTheme();

  return (
    <Styled.IconButton
      onClick={() => {
        navigator.clipboard.writeText(copyText);
        toggleSnackbar({
          type: "success",
          message: t("common:copied"),
        });
      }}
      {...props}
    >
      <icons.actions.copy.component />
    </Styled.IconButton>
  );
};

export default CopyActionButton;
