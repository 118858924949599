import { Card } from "@involve-software/uikit";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.colors["text-primary"]};
`;

export const Subtitle = styled.span`
  text-align: center;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors["text-secondary"]};
`;

export const BanksPaymentCard = styled(Card)`
  width: 100%;
`;

export const ReturnLink = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  text-decoration: none;
`;

export const DetailsContent = styled.div`
  max-width: 446px;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DetailsFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
  margin-top: 16px;

  // TODO: Could be included in uikit for readonly inputs
  input:read-only {
    pointer-events: none;
    caret-color: transparent;
  }
`;

export const CopyButton = styled.div`
  cursor: pointer;
`;
