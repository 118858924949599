import React from "react";
import { useTheme } from "@involve-software/uikit";
import { Helmet } from "react-helmet";

const Head = () => {
  const theme = useTheme();

  return (
    <Helmet>
      <meta charSet="utf-8" />
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <title>Pay</title>
      <link rel="stylesheet" href={theme.typography.source} />
    </Helmet>
  );
};

export default Head;
