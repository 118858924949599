import { Alert } from "@involve-software/uikit";
import styled from "styled-components";

export const SideInfo = styled.div`
  flex: 0.45;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-16"]};
`;

export const InfoAlert = styled(Alert)`
  span + span {
    font-size: 13px;
  }
`;
