import React from "react";

import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import useInfo from "modules/payment/utils/hooks/useInfo";

import WarningBlock from "./Warning";
import InfoBlock from "./Info";

import * as Styled from "./index.styled";

interface InfoBlocksProps extends React.HTMLAttributes<HTMLDivElement> {}

const InfoBlocks: React.FC<InfoBlocksProps> = ({ ...props }) => {
  const { paymentId } = useParams();

  const { watch } = useFormContext();

  const { warning, info } = useInfo(paymentId as string, watch("payway"));

  if (!warning.data || !info.data) {
    return null;
  }

  return (
    <Styled.InfoBlocks {...props}>
      <WarningBlock
        isLoading={warning.isLoading && warning.isFetching}
        data={warning.data}
      />
      <InfoBlock
        isLoading={info.isLoading && info.isFetching}
        data={info.data}
      />
    </Styled.InfoBlocks>
  );
};

export default InfoBlocks;
