import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "@involve-software/uikit";

import ErrorStatusLabel from "modules/result/components/StatusLabels/Error";
import { ActionButton } from "../index.styled";

const ErrorStatus = () => {
  const [searchParams] = useSearchParams();

  const failedUrl = searchParams.get("failedUrl");

  const { t } = useTranslation("result");

  return (
    <>
      <ErrorStatusLabel label={t("error.label")} />
      {failedUrl && (
        <Link href={failedUrl}>
          <ActionButton variant="outlined" fullWidth>
            {t("error.action")}
          </ActionButton>
        </Link>
      )}
    </>
  );
};

export default ErrorStatus;
