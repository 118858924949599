import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { env } from "utils/env";
import * as Styled from "./index.styled";

const AccessDeniedPage = () => {
  const { t } = useTranslation("errors");

  const [searchParams] = useSearchParams();

  const ip = searchParams.get("ip");
  const country = searchParams.get("country");

  return (
    <Styled.AccessDenied>
      <Styled.Lock />

      <Styled.AccessDeniedTitle>
        {t("accessDenied.title")}
      </Styled.AccessDeniedTitle>
      {env.REACT_APP_SUPPORT_MAIL && (
        <Styled.SupportText>
          {t("accessDenied.support")}{" "}
          <Styled.SupportLink href={`mailto:${env.REACT_APP_SUPPORT_MAIL}`}>
            {env.REACT_APP_SUPPORT_MAIL}
          </Styled.SupportLink>
        </Styled.SupportText>
      )}
      <Styled.RequestDetails>
        {country && (
          <Styled.RequestDetail>
            {t("accessDenied.details.country")}:{" "}
            <Styled.RequestValue>{country}</Styled.RequestValue>
          </Styled.RequestDetail>
        )}
        {ip && (
          <Styled.RequestDetail>
            {t("accessDenied.details.ip")}:{" "}
            <Styled.RequestValue>{ip}</Styled.RequestValue>
          </Styled.RequestDetail>
        )}
      </Styled.RequestDetails>
    </Styled.AccessDenied>
  );
};

export default AccessDeniedPage;
