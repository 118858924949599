import { type LoaderFunction, redirect } from "react-router-dom";

import { getPaymentMethods, getPaymentPayways } from "api/payment/payways";
import sessionTypeValidators from "./utils/validators";
import { PaymentSessionType } from "utils/constants";
import { getPaymentSession } from "api/payment";

export const paymentLoader: LoaderFunction = async ({ params, request }) => {
  if (!params.paymentId) {
    throw new Error("invalid params");
  }

  const { data: paymentSession } = await getPaymentSession(params.paymentId);

  const validator = sessionTypeValidators[paymentSession?.type];
  return validator?.(params.paymentId, request) ?? paymentSession;
};

export const redirectLoader: LoaderFunction = async ({ params, request }) => {
  if (!params.paymentId) {
    throw new Error("invalid params");
  }

  const { data: paymentSession } = await getPaymentSession(params.paymentId);

  if (paymentSession.type === PaymentSessionType.FULL) {
    return redirect(request.url + "/methods");
  }
};

export const methodsLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error("invalid params");
  }

  const { data: paymentMethods } = await getPaymentMethods(params.paymentId);
  return paymentMethods;
};

export const detailsLoader: LoaderFunction = async ({ request, params }) => {
  const url = new URL(request.url);

  if (!params.paymentId) {
    throw new Error("invalid params");
  }

  const { data: session } = await getPaymentSession(params.paymentId);
  if (session.type === PaymentSessionType.PRE) {
    return {};
  }

  const payMethodId = url.searchParams.get("paymethodId");

  if (!payMethodId) {
    throw new Error("invalid params");
  }

  const { data: payways } = await getPaymentPayways(
    params.paymentId,
    +payMethodId
  );

  return { payways };
};
