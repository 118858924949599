export function isObject(o: any) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== "function";
}

export function toCamel(s: string) {
  if (!s.includes("_")) return s;

  return s.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("_", "");
  });
}

export function toSnake(s: string) {
  return s
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + "_" + m[1];
    })
    .toLowerCase();
}

export function deepKeysTransformFactory(transform: (key: string) => string) {
  function deepKeysTransform<T extends { [key: string]: any } | any[]>(
    o: T,
    blacklists?: string[][]
  ): object {
    if (isObject(o)) {
      return Object.keys(o).reduce((previous, current) => {
        const transformedKey = transform(current);

        const keyValue = (o as { [key: string]: any })[current];

        if (blacklists) {
          const keyBlacklists = blacklists
            .filter((bl) => bl[0] === transformedKey)
            .map((bl) => bl.slice(1));

          if (keyBlacklists.find((bl) => bl.length === 0)) {
            previous[transformedKey] = keyValue;
          } else {
            previous[transformedKey] = deepKeysTransform(
              keyValue,
              keyBlacklists
            );
          }
        } else {
          previous[transformedKey] = deepKeysTransform(keyValue);
        }

        return previous;
      }, {} as { [key: string]: any });
    } else if (Array.isArray(o)) {
      return o.map((i) => {
        return deepKeysTransform(i, blacklists);
      });
    }

    return o;
  }

  return deepKeysTransform;
}

export const camelize = deepKeysTransformFactory(toCamel);
export const snakify = deepKeysTransformFactory(toSnake);
