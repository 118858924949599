import { Card, Link } from "@involve-software/uikit";
import styled from "styled-components";

import { down } from "utils/breakpoints";
import { CopyBlock } from "components/CopyBlock/index.styled";

export const CactusCard = styled(Card)`
  padding-top: ${({ theme }) => theme.spacing.primitives["s-24"]};
  padding-bottom: ${({ theme }) => theme.spacing.primitives["s-36"]};
  width: 100%;

  ${down("md")} {
    padding-bottom: ${({ theme }) => theme.spacing.primitives["s-48"]};
  }
`;

export const CactusTitle = styled(Card.Title)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.primitives["s-16"]};
  font-size: 18px;
  text-align: center;

  ${down("md")} {
    font-size: 16px;
  }
`;

export const CactusSubtitle = styled(Card.Subtitle)`
  display: block;
  color: ${({ theme }) => theme.colors["text-primary"]};
  margin-bottom: ${({ theme }) => theme.spacing.primitives["s-16"]};
  text-align: center;
`;

export const CactusDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 446px;
  margin: 0 auto;

  ${CopyBlock} + ${CopyBlock} {
    margin-top: 36px;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.primitives["s-16"]};

  &:not(:empty) {
    margin-top: ${({ theme }) => theme.spacing.primitives["s-36"]};
  }
`;

export const ButtonLinkWrap = styled(Link)`
  text-decoration: none;
  flex-shrink: 0;
  width: 100%;
  max-width: 190px;

  button {
    width: 100%;
  }
`;
