import React from "react";

import MethodsLink from "modules/payment/components/MethodsLink";

import * as Styled from "./index.styled";

interface PaymentDetailsLayoutProps extends React.PropsWithChildren {
  showMethodsLink?: boolean;
}

const PaymentDetailsLayout: React.FC<PaymentDetailsLayoutProps> = ({
  children,
  showMethodsLink = true,
}) => (
  <Styled.Content>
    {showMethodsLink && <MethodsLink />}
    {children}
  </Styled.Content>
);

export default PaymentDetailsLayout;
