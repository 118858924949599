import { keyframes } from "styled-components/macro";

export const rotate = keyframes`
    0% { 
        transform: rotate(0deg); 
    }

    100% { 
        transform: rotate(360deg); 
    }
`;
