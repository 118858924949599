import styled from "styled-components";

import ErrorStatusLabel from "modules/result/components/StatusLabels/Error";
import { down } from "utils/breakpoints";

export const Error = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives["s-08"]};
  font: ${({ theme }) => theme.typography["paragraph-medium"]};
  margin-top: ${({ theme }) => theme.spacing.primitives["s-32"]};
  text-align: center;
  color: ${({ theme }) => theme.colors["text-subtle"]};

  ${down("sm")} {
    margin-top: ${({ theme }) => theme.spacing.primitives["s-24"]};
  }
`;

export const StatusLabel = styled(ErrorStatusLabel)`
  div {
    font: ${({ theme }) => theme.typography["heading-small"]};
  }
`;
