import React from "react";
import { useTranslation } from "react-i18next";

import ErrorCard from "components/Errors/ErrorCard";

const NotFoundPage = () => {
  const { t } = useTranslation("errors");

  return (
    <ErrorCard label={t("notFound.label")}>
      {t("notFound.description")}
    </ErrorCard>
  );
};

export default NotFoundPage;
