import React from "react";

import * as Styled from "./index.styled";

interface TextSwitchProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const TextSwitch: React.FC<TextSwitchProps> = ({ active, ...props }) => (
  <Styled.TextSwitch type="button" $isActive={active} {...props} />
);

export default TextSwitch;
