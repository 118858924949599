import React from "react";
import { Select } from "@involve-software/uikit";
import { useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";

import type { CryptoDetailsLoaderData } from "modules/crypto/loaders";
import BlockchainOption from "./Option";

import * as Styled from "./index.styled";

const BlockchainSelectDialog: React.ComponentProps<typeof Select>["Menu"] = ({
  open,
  onChangeOpen: handleOpen,
  value,
  onValueChange: handleValue,
}) => {
  const { paywaysData } = useLoaderData() as CryptoDetailsLoaderData;

  const { t } = useTranslation("crypto");

  return (
    <Styled.SelectDialog
      title={<Styled.Title>{t("details.blockchainDialog.title")}</Styled.Title>}
      open={open}
      onClose={() => handleOpen(false)}
    >
      <Styled.Description>
        {t("details.blockchainDialog.description")}
      </Styled.Description>
      <Styled.Options>
        {paywaysData?.payways.map(({ id, name, currency, alias }) => (
          <BlockchainOption
            key={alias}
            title={name}
            isSelected={+value === id}
            onClick={() => {
              handleValue({
                id,
                name,
                currency,
                alias,
              });

              handleOpen(false);
            }}
          />
        ))}
      </Styled.Options>
    </Styled.SelectDialog>
  );
};

export default BlockchainSelectDialog;
